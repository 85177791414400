import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useGetExperts } from '@root/modules/experts/hooks/use-get-experts';
import { authSelector } from '@root/shared-files/modules/auth/store';

export const useCheckActiveExpertsQuota = () => {
  let maxEACount = useSelector(authSelector.maxEACount);

  if (maxEACount === '∞') {
    maxEACount = Infinity;
  }

  const { data: experts } = useGetExperts();

  const possibleActiveExpertsLeft = useMemo(() => {
    if (maxEACount && experts) {
      return Number(maxEACount) - experts.filter((item) => item.isEnabled).length;
    }
    return 0;
  }, [maxEACount, experts]);

  return { possibleActiveExpertsLeft };
};
