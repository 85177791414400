import { Selector, createSelector } from '@reduxjs/toolkit';

import { IAppState } from '@root/infra/store';
import { IOrdersState } from '@root/modules/orders/store/orders.slice';

const getState = (state: IAppState) => state.orders;
const getShouldListenOrders = (state: IOrdersState) => state.shouldListenOrders;
const getList = (state: IOrdersState) => state.orders;
const getClosedOrders = (state: IOrdersState) => state.closedOrders;

export const ordersSelector = {
  ordersByTicket: createSelector<[Selector<IAppState, IOrdersState>], IOrdersState['orders']>(getState, getList),
  closedOrderTickets: createSelector<[Selector<IAppState, IOrdersState>], IOrdersState['closedOrders']>(getState, getClosedOrders),
  shouldListenOrders: createSelector<[Selector<IAppState, IOrdersState>], IOrdersState['shouldListenOrders']>(getState, getShouldListenOrders),
};
