import { FormikTouched, setNestedObjectValues, useFormikContext } from 'formik';
import { useCallback, useState } from 'react';

import { queryClient } from '@root/infra/query';
import { notify } from '@root/shared/utils/notification';

import { usePartialEditContext } from '../context/partial-edit.context';
import { CreateExpertDto } from '../dtos/create-expert.dto';
import { createExpertService } from '../services/create-expert.service';
import { CreateExpertStepKey } from './use-create-expert-steps';

export const useExpertPartialEdit = (step: CreateExpertStepKey) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { initialValues, values, errors, validateForm, setValues, setTouched } = useFormikContext<CreateExpertDto>();

  const { editingSteps, setEditingSteps } = usePartialEditContext();

  const handleSetEditingSteps = useCallback(
    (step: CreateExpertStepKey, value: boolean) => {
      setEditingSteps((prev) => ({
        ...prev,
        [step]: value,
      }));
    },
    [setEditingSteps],
  );

  const cancelChanges = useCallback(() => {
    const newValues = { ...values };

    if (step === CreateExpertStepKey.Account) {
      newValues.account = initialValues.account;
      newValues.symbols = initialValues.symbols;
    }

    if (step === CreateExpertStepKey.OrderOptions) {
      newValues.orderDirection = initialValues.orderDirection;
      newValues.orderType = initialValues.orderType;
    }

    if (step === CreateExpertStepKey.AdvancedSettings) {
      newValues.name = initialValues.name;
      newValues.providerId = initialValues.providerId;
    }

    if (step === CreateExpertStepKey.MoneyManagement) {
      newValues.manualMoneyManagement = initialValues.manualMoneyManagement;
      newValues.maxManualActiveTrades = initialValues.maxManualActiveTrades;
      newValues.signalMoneyManagement = initialValues.signalMoneyManagement;
      newValues.maxSignalActiveTrades = initialValues.maxSignalActiveTrades;
      newValues.maxDrawDownLimit = initialValues.maxDrawDownLimit;
    }

    if (step === CreateExpertStepKey.BreakEven) {
      newValues.breakEven = initialValues.breakEven;
    }

    if (step === CreateExpertStepKey.PartialTakeProfits) {
      newValues.partialClose = initialValues.partialClose;
    }

    if (step === CreateExpertStepKey.StopLossTakeProfit) {
      newValues.manualSlTp = initialValues.manualSlTp;
      newValues.signalSlTp = initialValues.signalSlTp;
      newValues.trailStop = initialValues.trailStop;
    }

    if (step === CreateExpertStepKey.AdvancedSettings) {
      newValues.slippage = initialValues.slippage;
      newValues.strategy = initialValues.strategy;
    }

    if (step === CreateExpertStepKey.Trigger) {
      newValues.conditionalClosure.use = initialValues.conditionalClosure.use;
      newValues.conditionalClosure.amount = initialValues.conditionalClosure.amount;
    }

    setValues(newValues);

    const timeout = setTimeout(() => {
      validateForm();
      clearTimeout(timeout);
    }, 100);

    handleSetEditingSteps(step, false);
  }, [values, step, initialValues, validateForm, handleSetEditingSteps, setValues]);

  const setEditing = useCallback(() => {
    handleSetEditingSteps(step, true);
  }, [handleSetEditingSteps, step]);

  const saveSettings = useCallback(async () => {
    if (Object.keys(errors).length > 0) {
      setTouched(setNestedObjectValues<FormikTouched<CreateExpertDto>>(errors, true));
      setEditingSteps((prev) => {
        const account = prev[CreateExpertStepKey.Account] || !!errors.account || !!errors.symbols;
        const orderOptions = prev[CreateExpertStepKey.OrderOptions] || !!errors.orderDirection || !!errors.orderType;
        const trigger = prev[CreateExpertStepKey.AdvancedSettings] || !!errors.name || !!errors.providerId || !!errors.conditionalClosure?.amount;
        const moneyManagement =
          prev[CreateExpertStepKey.MoneyManagement] ||
          !!errors.manualMoneyManagement ||
          !!errors.maxManualActiveTrades ||
          !!errors.signalMoneyManagement ||
          !!errors.maxSignalActiveTrades ||
          !!errors.sessionManagement;
        const breakEven = prev[CreateExpertStepKey.PartialTakeProfits] || !!errors.breakEven;
        const partialTakeProfits = prev[CreateExpertStepKey.PartialTakeProfits] || !!errors.partialClose;
        const stopLossTakeProfit = prev[CreateExpertStepKey.StopLossTakeProfit] || !!errors.manualSlTp || !!errors.signalSlTp || !!errors.trailStop;
        const advanced = prev[CreateExpertStepKey.AdvancedSettings] || !!errors?.slippage || !!errors.strategy;

        return {
          ...prev,
          [CreateExpertStepKey.Account]: account,
          [CreateExpertStepKey.OrderOptions]: orderOptions,
          [CreateExpertStepKey.AdvancedSettings]: advanced,
          [CreateExpertStepKey.BreakEven]: breakEven,
          [CreateExpertStepKey.MoneyManagement]: moneyManagement,
          [CreateExpertStepKey.PartialTakeProfits]: partialTakeProfits,
          [CreateExpertStepKey.StopLossTakeProfit]: stopLossTakeProfit,
          [CreateExpertStepKey.Trigger]: trigger,
        };
      });

      return;
    }

    setLoading(true);
    const result = await createExpertService(values);

    if (result.status === 200) {
      queryClient.invalidateQueries(['experts', values.id]);
      queryClient.invalidateQueries(['experts'], { exact: false });
      notify({ type: 'success', text: 'Smart Assist has been edited' });
    } else {
      notify({ type: 'danger', text: result.payload }, { data: result.errorData });
      setValues(initialValues);
    }
    setLoading(false);
    setEditingSteps({
      [CreateExpertStepKey.Account]: false,
      [CreateExpertStepKey.AdvancedSettings]: false,
      [CreateExpertStepKey.BreakEven]: false,
      [CreateExpertStepKey.MoneyManagement]: false,
      [CreateExpertStepKey.PartialTakeProfits]: false,
      [CreateExpertStepKey.Preview]: false,
      [CreateExpertStepKey.StopLossTakeProfit]: false,
      [CreateExpertStepKey.Trigger]: false,
      [CreateExpertStepKey.OrderOptions]: false,
    });
  }, [errors, values, initialValues, setEditingSteps, setTouched, setValues]);

  return {
    editing: editingSteps[step],
    loading,
    setEditing,
    saveSettings,
    setLoading,
    cancelChanges,
  };
};
