import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { ISymbolWithType } from '@root/modules/accounts/types/symbol-with-type';
import { handleAxiosError } from '@root/shared/utils/helpers/handle-axios-error';
import type { IHttpResponse } from '@root/shared/utils/http-service';

export type GetSymbolsResponse = IHttpResponse<200, string[]> | IHttpResponse<400, string>;
export type GetSymbolsWithTypeResponse = IHttpResponse<200, ISymbolWithType[]> | IHttpResponse<400, string>;

export const getSymbols = async (): Promise<GetSymbolsResponse> => {
  try {
    const response = await fetcher(`/symbols`, { baseURL: import.meta.env.VITE_FX_CORE_HOST });

    return {
      status: 200,
      payload: response.data,
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};

export const getSymbolsWithType = async (): Promise<GetSymbolsWithTypeResponse> => {
  try {
    const response = await fetcher(`/symbols/with-type`, { baseURL: import.meta.env.VITE_FX_CORE_HOST });

    return {
      status: 200,
      payload: response.data,
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};
