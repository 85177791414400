import { QueryFunction } from 'react-query';

import { getOrderHistoryCount } from '../services/get-orders-history-count.service';

export type GetOrdersHistoryCountQueryKey = ['orders-history-count'];
export type GetOrdersHistoryCountData = {
  experts: Record<string, string>;
  accounts: Record<string, string>;
};

export const GET_ORDERS_HISTORY_COUNT: QueryFunction<GetOrdersHistoryCountData, GetOrdersHistoryCountQueryKey> = async () => {
  const result = await getOrderHistoryCount();

  if (result.status === 200) {
    return result.payload;
  } else {
    throw new Error(result.payload);
  }
};
