import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { queryClient } from '@root/infra/query';
import { AccountStatus } from '@root/modules/accounts/enums';
import { useGetAccounts } from '@root/modules/accounts/hooks/use-get-accounts';
import { useGetExpertStatus } from '@root/modules/experts/hooks/use-get-expert-status';
import { getOpenOrdersService } from '@root/modules/orders/services/get-open-orders.service';
import { useGhostLogin } from '@root/shared-files/modules/auth/hooks/use-ghost-login';
import { authSelector } from '@root/shared-files/modules/auth/store';
import { PageLinks } from '@root/shared/constants/pageLinks';
import { useCopyToClipboard } from '@root/shared/hooks/use-copy-to-clipboard';
import { CopyIcon } from '@root/shared/icons/copy-icon';
import { EyeIcon } from '@root/shared/icons/eye-icon';
import { PencilIcon } from '@root/shared/icons/pencil-icon';
import { TrashIcon } from '@root/shared/icons/trash-icon';
import { WarningCircleIcon } from '@root/shared/icons/warning-circle-icon';
import { notify } from '@root/shared/utils/notification';

import { DELETE_EXPERT_MUTATION, DeleteExpertMutationData, DeleteExpertMutationError, DeleteExpertMutationValues } from '../mutations/delete-expert.mutation';
import { STOP_EXPERT_MUTATION, StopExpertMutationData, StopExpertMutationError, StopExpertMutationValues } from '../mutations/stop-expert.mutation';
import { SYNC_COPIED_MASTER_EXPERT, SyncCopiedExpertMutationData, SyncCopiedExpertMutationError } from '../mutations/update-copied-expert.mutation';
import { IExpert } from '../types/expert';
import { useRunExpert } from './use-run-expert';

export const useExpertItem = (data: IExpert) => {
  const userId = useSelector(authSelector.userId);
  const { t } = useTranslation('forex-experts', { keyPrefix: 'item' });
  const { t: commonT } = useTranslation('common');
  const { isCopied, handleCopy } = useCopyToClipboard();
  const navigate = useNavigate();

  const { isEnabled, status, isPaused } = useGetExpertStatus(data);
  const { data: accounts } = useGetAccounts();

  const [removeIsOpen, setRemoveIsOpen] = useState<boolean>(false);
  const [removeIsDisabled, setRemoveIsDisabled] = useState<boolean>(false);
  const [stopIsOpen, setStopIsOpen] = useState<boolean>(false);

  const { runExpert, isLoading } = useRunExpert();

  const mutationOptions = useMemo(
    () => ({
      onSuccess: () => {
        queryClient.invalidateQueries(['experts']);
      },
      onError: (error: Error) => notify({ type: 'danger', text: error.message }),
    }),
    [],
  );

  const deleteMutation = useMutation<DeleteExpertMutationData, DeleteExpertMutationError, DeleteExpertMutationValues>(DELETE_EXPERT_MUTATION);
  const stopRunMutation = useMutation<StopExpertMutationData, StopExpertMutationError, StopExpertMutationValues>(STOP_EXPERT_MUTATION);
  const syncCopiedExpert = useMutation<SyncCopiedExpertMutationData, SyncCopiedExpertMutationError, string>(SYNC_COPIED_MASTER_EXPERT);

  const getExpertOpenOrders = useCallback(async () => {
    const isConnected = accounts.find((account) => account.id === data.accountId)?.status === AccountStatus.Connected;
    if (!isConnected) {
      return [];
    }
    const { payload: openOrders } = await getOpenOrdersService({ accountId: data?.accountId });
    const expertOpenOrders = (!!Array.isArray(openOrders) && openOrders.filter((order) => order.expertId === data.id)) || [];

    return expertOpenOrders;
  }, [accounts, data.accountId, data.id]);

  const handleDelete = useCallback(async () => {
    if (isEnabled) {
      await stopRunMutation.mutateAsync(data.id);
    }
    await deleteMutation.mutateAsync(data.id, mutationOptions);
    setRemoveIsOpen(false);
    navigate(PageLinks.experts);
  }, [data.id, deleteMutation, stopRunMutation, mutationOptions, setRemoveIsOpen, navigate, isEnabled]);

  const handleDeleteExpert = useCallback(async () => {
    setRemoveIsDisabled(false);
    const expertOpenOrders = await getExpertOpenOrders();

    if (expertOpenOrders.length) {
      setRemoveIsDisabled(true);
    }

    setRemoveIsOpen(true);
  }, [getExpertOpenOrders, setRemoveIsDisabled, setRemoveIsOpen]);

  const handleStopExpert = useCallback(async () => {
    const expertOpenOrders = await getExpertOpenOrders();
    if (expertOpenOrders.length) {
      setStopIsOpen(true);
    } else {
      stopRunMutation.mutate(data.id, mutationOptions);
    }
  }, [setStopIsOpen, data, stopRunMutation, mutationOptions, getExpertOpenOrders]);

  const handleEdit = useCallback(() => {
    navigate(PageLinks.updateExpert + `?id=${data.id}`);
  }, [data.id, navigate]);

  const handleSync = useCallback(async () => {
    if (isEnabled) {
      await stopRunMutation.mutateAsync(data.id);
      const result = await syncCopiedExpert.mutateAsync(data.id);

      if (result.status === 200) {
        await runExpert({
          ...result.payload,
        });
      }
      return;
    }

    syncCopiedExpert.mutate(data.id, mutationOptions);
  }, [data.id, isEnabled, mutationOptions, runExpert, stopRunMutation, syncCopiedExpert]);

  const { hideActions } = useGhostLogin();

  const cardActions = useMemo(
    () =>
      [
        {
          icon: CopyIcon,
          hoverText: isCopied ? commonT('copied') : t('copyExpertId', { keyPrefix: 'actions' }),
          onClick: () => handleCopy(data.id),
          isDisabled: false,
          visible: true,
        },
        {
          icon: PencilIcon,
          hoverText: t('editExpert', { keyPrefix: 'actions' }),
          onClick: handleEdit,
          isDisabled: false,
          visible: true,
        },
        {
          icon: TrashIcon,
          hoverText: t('deleteExpert', { keyPrefix: 'actions' }),
          onClick: () => handleDeleteExpert(),
          isDisabled: isEnabled || hideActions || isPaused,
          visible: true,
        },
        {
          hoverText: t('showDetails', { keyPrefix: 'actions' }),
          icon: EyeIcon,
          onClick: () => navigate(`/assists/${data.id}`),
          visible: true,
        },
        {
          hoverText: t('inactive', { keyPrefix: 'item.messages', ns: 'forex-accounts' }),
          icon: WarningCircleIcon,
          visible: data.account?.isInvalid,
        },
      ].filter((item) => item.visible),
    [isCopied, isEnabled, hideActions, isPaused, commonT, t, handleEdit, handleCopy, data.id, handleDeleteExpert, navigate, data.account?.isInvalid],
  );

  const tableActions = useMemo(
    () => [
      {
        icon: PencilIcon,
        hoverText: t('editExpert', { keyPrefix: 'actions' }),
        onClick: handleEdit,
        isDisabled: false,
      },
      {
        icon: TrashIcon,
        hoverText: t('deleteExpert', { keyPrefix: 'actions' }),
        onClick: () => handleDeleteExpert(),
        isDisabled: isEnabled || hideActions || isPaused,
      },
      {
        icon: EyeIcon,
        hoverText: t('showDetails', { keyPrefix: 'actions' }),
        onClick: () => navigate(`/assists/${data.id}`),
      },
    ],
    [t, handleEdit, isEnabled, hideActions, isPaused, handleDeleteExpert, navigate, data.id],
  );

  return {
    cardActions,
    tableActions,
    stopRunMutation,
    deleteMutation,
    removeIsOpen,
    setRemoveIsOpen,
    runExpert,
    handleSync,
    isLoading,
    userId,
    mutationOptions,
    handleDeleteExpert,
    handleDelete,
    status,
    isEnabled,
    isPaused,
    stopIsOpen,
    removeIsDisabled,
    setStopIsOpen,
    handleStopExpert,
  };
};
