import clsx from 'clsx';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SymbolsPopover } from '@root/modules/experts/components/symbols-popover';
import { useGetMarketplaceTradeIdeaProvider } from '@root/modules/marketplace/hooks/use-get-marketplace-trade-idea-provider';
import { useProviderTraders } from '@root/modules/marketplace/hooks/use-provider-traders';
import { Button } from '@root/shared/ui/button';
import { ImageWithFallback } from '@root/shared/ui/image/image-with-fallback';
import { Text } from '@root/shared/ui/typography';

import { IPreset } from '../types/preset';

export type PresetsCardProps = {
  data: IPreset;
  loading: boolean;
  handleCopy: () => void;
  className?: string;
};

export const PresetsCard: FC<PresetsCardProps> = ({ data, loading, handleCopy, className = '' }) => {
  const { t } = useTranslation('forex-experts', { keyPrefix: 'presets' });
  const { data: presetsProvider } = useGetMarketplaceTradeIdeaProvider(data?.providerId as string, {});

  const displaySymbols = data.symbols.slice(0, 3);
  const hiddenSymbols = data.symbols.slice(3);

  return (
    <div className={clsx('p-5 py-4 bg-gray-800 rounded relative', className)}>
      <Text className='text-gray-100 font-semibold'>{data.name}</Text>

      {!!presetsProvider && (
        <div className='flex mt-2'>
          <ImageWithFallback width={20} height={20} src={presetsProvider.logo} label={presetsProvider?.name} />
          <Text size='sm' className='ml-1'>
            {presetsProvider?.name || '-'}
          </Text>
        </div>
      )}

      <div className='h-px bg-color-divider my-3'></div>

      {!!displaySymbols.length && (
        <div className='flex flex-wrap -m-1'>
          {displaySymbols.map((symbol) => (
            <div key={symbol} className='m-1 text-[15px] bg-gray-700 px-2 py-px rounded-full'>
              {symbol}
            </div>
          ))}
          {!!hiddenSymbols.length && (
            <SymbolsPopover symbols={hiddenSymbols}>
              <button className='m-1 text-[15px] bg-gray-700 px-2 py-px rounded-full hover:bg-gray-900 transition'>+{hiddenSymbols.length}</button>
            </SymbolsPopover>
          )}
        </div>
      )}

      <div className='flex justify-between items-center mt-4'>
        <div>
          <Text size='sm' className=' text-grayscale-400'>
            {t('item.copies')}
          </Text>
          <Text size='sm' className='font-semibold'>
            {data.copies || 0}
          </Text>
        </div>

        <Button onClick={handleCopy} variant='primary' outlined loading={loading}>
          <div className='children'>{t('item.copy')}</div>
        </Button>
      </div>
    </div>
  );
};
