import { ExchangeType } from '@3lgn/shared/dist/constants/ccxt';
import {
  MarketPlaceTradeIdeaItem,
  MarketPlaceTradeIdeaPlan,
  MarketPlaceTradeIdeaProduct,
  MarketPlaceTradeIdeaSubscription,
} from 'src/modules/marketplace/types/marketplace-trade-idea';

import { globalRound } from '@root/shared/utils/number/round';

const statistics = {
  'Sebas-FX': {
    pnl: 1.9,
    profitFactor: 5.71,
    avgMonthProfit: '53.6 pips (0.45%)',
    avgMonthLoss: '18.5 pips (-0.16%)',
    maxDrawDown: '1.03%',
    avgTradeDuration: '1 days 3h',
  },
  'Gus-FX': {
    pnl: 1.27,
    profitFactor: 11.66,
    avgMonthProfit: '61.9 pips (0.52%)',
    avgMonthLoss: '11.7 pips (-0.10%)',
    maxDrawDown: '0.86%',
    avgTradeDuration: '1 days 10h',
  },
  'Fab-FX': {
    pnl: 2.39,
    profitFactor: 2.48,
    avgMonthProfit: '80.5 pips (0.68%)',
    avgMonthLoss: '45.6 pips (-0.38%)',
    maxDrawDown: '1.54%',
    avgTradeDuration: '2 days 5h',
  },
  'Luk-FX': {
    pnl: 2.21,
    profitFactor: 5.53,
    avgMonthProfit: '57.4 pips (0.48%)',
    avgMonthLoss: '24.0 pips (-0.20%)',
    maxDrawDown: '0.84%',
    avgTradeDuration: '1 days 2h',
  },
  'Stef-FX': {
    pnl: 1.61,
    profitFactor: 4.09,
    avgMonthProfit: '46.7 pips (0.39%)',
    avgMonthLoss: '14.1 pips (-0.12%)',
    maxDrawDown: '0.87%',
    avgTradeDuration: '0 days 18h',
  },
  'Merlin-FX': {
    pnl: 1.7,
    profitFactor: 5.74,
    avgMonthProfit: '61.85 pips (0.52%)',
    avgMonthLoss: '18.26 pips (-0.15%)',
    avgTradeDuration: '1 days 1h',
  },
  Darwin: {
    pnl: 2.53,
    avgTradeDuration: '8.56h',
  },
  Adri: {
    pnl: 2.18,
    avgTradeDuration: '6.89h',
  },
  David: {
    pnl: 2.88,
    avgTradeDuration: '3.5h',
  },
  Erika: {
    pnl: 2.61,
    avgTradeDuration: '15.19h',
  },
  Andres: {
    pnl: 1.6,
    avgTradeDuration: '0.94h',
  },
  Nate: {
    pnl: 3.66,
    avgTradeDuration: '1.91h',
  },
  Ray: {
    pnl: 2.45,
    avgTradeDuration: '3.14h',
  },
  Fran: {
    pnl: 1.73,
    avgTradeDuration: '3.45h',
  },
};

export class MarketPlaceTradeIdeaProductMapper {
  public static toDomain(data): MarketPlaceTradeIdeaProduct {
    return {
      id: data.id,
      name: data.name,
      moduleId: data.moduleId,
      description: data.description,
      group: data.group,
      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
    };
  }
}

export class MarketPlaceTradeIdeaPlanMapper {
  public static toDomain(data): MarketPlaceTradeIdeaPlan {
    return {
      id: data.id,
      name: data.name,
      productId: data.productId,
      product: MarketPlaceTradeIdeaProductMapper.toDomain(data.product),
      price: (data.price || 0) / 100,
      currency: data.currency,
      recurringIntervalUnit: data.recurringIntervalUnit,
      recurringIntervalLength: data.recurringIntervalLength,
      renewable: data.renewable,
      isTrialOnly: data.isTrialOnly,
      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
    };
  }
}

export class MarketPlaceTradeIdeaSubscriptionMapper {
  public static toDomain(data): MarketPlaceTradeIdeaSubscription {
    return {
      createdAt: data.createdAt,
      customer: data.customer,
      customerId: data.customerId,
      expiresAt: data.expiresAt,
      gatewaySubscription: data.gatewaySubscription,
      id: data.id,
      plan: MarketPlaceTradeIdeaPlanMapper.toDomain(data.plan),
      planId: data.planId,
      updatedAt: data.updatedAt,
    };
  }
}

export class MarketplaceTradeIdeaMapper {
  public static toDomain(data): MarketPlaceTradeIdeaItem {
    const totalGain = data.statistics.gain_per_month.reduce((acc, item) => acc + item.gain, 0);

    const avgMonthProfit = totalGain ? totalGain / data.statistics.gain_per_month.length : 0;
    const avgWinRatio = Number(data.statistics.wins) ? Number(data.statistics.wins) / (Number(data.statistics.wins) + Number(data.statistics.losses)) : 0;
    return {
      id: data.id,
      name: data.name,
      logo: data.image_url,
      statistics: {
        weeklyGain: data.statistics.gain_week,
        monthlyGain: data.statistics.gain_month,
        yearlyGain: data.statistics.gain_year,
        price: 0,
        precision: data.statistics.precision,
        subscribersCount: data.statistics.subscribers_count,
        pnl: data.statistics.pnl,
        avgMonthProfit: (globalRound(avgMonthProfit, 2) || 0).toString(),
        avgWinRatio: globalRound(avgWinRatio * 100, 2),
        profitFactor: 0,
        avgMonthLoss: '0',
        maxDrawDown: '0',
      },
      subscriptionStatus: data.subscription_status,
      plans: data.plans?.map((item) => MarketPlaceTradeIdeaPlanMapper.toDomain(item)),
      exchanges:
        data.statistics?.exchanges
          ?.filter((item) => item.name !== ExchangeType.FTX)
          .map((item) => ({
            exchange: item.name,
            coins: item.coins,
          })) || [],
      showSlWonInfo: data.show_sl_won_info,
      allowDca: data.allowed_dca,
      allowSimple: data.allowed_simple,
      allowGrid: data.allowed_grid,
      gainPerMonth: data.statistics.gain_per_month.map((obj) => {
        return {
          date: new Date(`${obj.year} ${obj.month} 01 UTC`),
          value: obj.gain,
        };
      }),
      subscription: data.subscription ? MarketPlaceTradeIdeaSubscriptionMapper.toDomain(data.subscription) : undefined,
      market: data.market,
      marketLabel: data.market === 'forex' ? 'Currencies (FX)' : data.market,
    };
  }
}
