import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { authSlice } from '@root/shared-files/modules/auth/store';
import { PROVIDER_TO_BE_FETCHED, SHOULD_REFRESH_TOKEN } from '@root/shared-files/modules/shared/constants/local-storage-keys';
import { cancelSubscription } from '@root/shared-files/modules/subscriptions/services/cancel-subscription.service';
import { deactivateSubscription } from '@root/shared-files/modules/subscriptions/services/deactivate-subscription.service';
import { getPaymentUrl } from '@root/shared-files/modules/subscriptions/services/get-payment-url.service';
import { reactivateSubscription } from '@root/shared-files/modules/subscriptions/services/reactivate-subscription.service';
import { useLocalStorage } from '@root/shared/hooks/useLocalStorage';
import { notify } from '@root/shared/utils/notification';

import { purchaseTradeIdeaProviderSubscription } from '../services/purchase-provider-subscription.service';
import { MarketPlaceTradeIdeaItem } from '../types/marketplace-trade-idea';
import { MarketPlaceTradeIdeaProvider } from '../types/marketplace-trade-idea-provider';
import { CHECKED_ALREADY } from './use-trade-idea-after-purchase-update';

export const useTradeIdeaSubscription = ({ subscriptionType }: { subscriptionType: 'month' | 'year' }) => {
  const [selectedProvider, setSelectedProvider] = useState<MarketPlaceTradeIdeaItem | MarketPlaceTradeIdeaProvider | null>(null);
  const [retryPaymentSubscription, setRetrySubscription] = useState<MarketPlaceTradeIdeaProvider | MarketPlaceTradeIdeaItem | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [retryPaymentLoadingId, setRetryPaymentLoadingId] = useState<string>('');
  const { t } = useTranslation('marketplace-trade-idea-modals');
  const [, setCheckedAlready] = useLocalStorage<boolean>(CHECKED_ALREADY, false);

  const isSubscribeOpen = useMemo(() => selectedProvider && (!selectedProvider.subscriptionStatus || selectedProvider.subscriptionStatus !== 'active'), [selectedProvider]);

  const isUnsubscribeOpen = useMemo(() => ['active', 'paused'].includes(selectedProvider?.subscriptionStatus || ''), [selectedProvider]);

  const dispatch = useDispatch();

  const onCancel = useCallback(() => {
    if (!loading) {
      setSelectedProvider(null);
    }
  }, [loading]);

  const handleSubscribeClick = useCallback(
    async (provider?: MarketPlaceTradeIdeaItem | MarketPlaceTradeIdeaProvider) => {
      const chosenProvider = provider || selectedProvider;

      setLoading(true);
      if (chosenProvider?.subscriptionStatus === 'canceled' && chosenProvider.subscription?.id) {
        const response = await reactivateSubscription(chosenProvider.subscription?.id);
        if (response.status === 200) {
          notify({
            title: t('reactivateSuccessText'),
            type: 'success',
          });
        } else {
          notify({
            title: response.payload,
            type: 'danger',
          });
        }

        localStorage.setItem(PROVIDER_TO_BE_FETCHED, chosenProvider?.id as string);
        dispatch(authSlice.actions.refreshSessionAfterTradeIdeaSubscription());
        setSelectedProvider(null);
      } else {
        const selectedPlan = chosenProvider?.plans?.find((plan) => plan.recurringIntervalUnit === subscriptionType);
        if (selectedPlan) {
          const response = await purchaseTradeIdeaProviderSubscription({
            planId: selectedPlan.id,
            providerId: chosenProvider?.id as string,
          });
          if (response.status === 200) {
            if (selectedPlan.price === 0) {
              notify({
                title: t('subscribeSuccessText'),
                type: 'success',
              });
              localStorage.setItem(PROVIDER_TO_BE_FETCHED, chosenProvider?.id as string);
              dispatch(authSlice.actions.refreshSessionAfterTradeIdeaSubscription());
              setSelectedProvider(null);
            } else {
              const approvalUrl = response.payload?.fields?.['_links']?.find((item) => item.rel === 'approvalUrl')?.href || '';
              setCheckedAlready(false);
              if (approvalUrl) {
                window.location.href = approvalUrl;
                localStorage.setItem(SHOULD_REFRESH_TOKEN, 'true');
              }
            }
          } else {
            notify({ type: 'danger', text: response.payload }, { data: response.errorData });
          }
        }
      }
      setLoading(false);
    },
    [selectedProvider, dispatch, t, subscriptionType, setCheckedAlready],
  );

  const handleUnsubscribeClick = useCallback(async () => {
    setLoading(true);
    if (selectedProvider?.subscription?.id) {
      const response = await (selectedProvider.subscriptionStatus === 'paused'
        ? deactivateSubscription(selectedProvider.subscription.id)
        : cancelSubscription(selectedProvider.subscription.id));
      if (response.status === 200) {
        notify({
          title: t('cancelSuccessText'),
          type: 'success',
        });
      } else {
        notify({
          title: response.payload,
          type: 'danger',
        });
      }

      // const timeout = setTimeout(() => {
      //   queryClient.refetchQueries(['marketplace-trade-idea-list'], { exact: false });
      //   queryClient.refetchQueries(['marketplace-trade-idea-provider', selectedProvider.id]);
      //   clearTimeout(timeout);
      // }, 3000);

      localStorage.setItem(PROVIDER_TO_BE_FETCHED, selectedProvider?.id as string);
      dispatch(authSlice.actions.refreshSessionAfterTradeIdeaSubscription());
    }
    setSelectedProvider(null);
    setLoading(false);
  }, [selectedProvider?.subscription?.id, selectedProvider?.subscriptionStatus, selectedProvider?.id, dispatch, t]);

  const retryModalOk = useCallback(async () => {
    if (retryPaymentSubscription) {
      setRetryPaymentLoadingId(retryPaymentSubscription.id as string);
      const response = await getPaymentUrl(retryPaymentSubscription?.subscription?.gatewaySubscription?.id || '');
      if (response.status === 200) {
        window.location.href = response.payload.paymentUrl;
        localStorage.setItem(SHOULD_REFRESH_TOKEN, 'true');
      } else {
        notify({
          title: response.payload,
          type: 'danger',
        });
      }
      setRetryPaymentLoadingId('');
    }
  }, [retryPaymentSubscription]);

  const handleRetryPayment = useCallback(
    async (data: MarketPlaceTradeIdeaProvider | MarketPlaceTradeIdeaItem) => {
      if (data.subscription?.plan?.recurringIntervalUnit === subscriptionType) {
        setRetryPaymentLoadingId(data.id as string);
        const response = await getPaymentUrl(data?.subscription?.gatewaySubscription?.id || '');
        if (response.status === 200) {
          window.location.href = response.payload.paymentUrl;
          localStorage.setItem(SHOULD_REFRESH_TOKEN, 'true');
        } else {
          notify({
            title: response.payload,
            type: 'danger',
          });
        }
        setRetryPaymentLoadingId('');
      } else {
        setRetrySubscription(data);
      }
    },
    [subscriptionType],
  );

  const state = {
    retryPaymentLoadingId,
    loading,
    selectedProvider,
    isSubscribeOpen,
    isUnsubscribeOpen,
    retryPaymentSubscription,
  };

  const handlers = {
    onCancel,
    handleSubscribeClick,
    handleUnsubscribeClick,
    setSelectedProvider,
    handleRetryPayment,
    setRetrySubscription,
    retryModalOk,
  };

  return [state, handlers] as [typeof state, typeof handlers];
};
