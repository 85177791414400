import { ReactNode } from 'react';
import { ToastOptions, toast } from 'react-toastify';

import { ToastCmp } from '@root/shared/ui/toast';

export type ToastProps = {
  title?: ReactNode;
  text?: string | { content: string } | ReactNode;
  type: 'info' | 'success' | 'danger';
};

export const notify = (toastInfo: ToastProps, options?: ToastOptions) => {
  if (toastInfo.type === 'danger') {
    const text = toastInfo.title || toastInfo.text;

    if (typeof text === 'string' && text.includes('ID=')) {
      const id = text.split('ID=')[1]?.split(' ')?.[0];

      toast(<ToastCmp toast={toastInfo} idToCopy={id} data={options?.data} />, options);

      return;
    }
  }

  toast(<ToastCmp toast={toastInfo} />, options);
};
