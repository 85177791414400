import { FormikHelpers } from 'formik';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { queryClient } from '@root/infra/query';
import { isMagicOrder } from '@root/modules/orders/utils/orders';
import { notify } from '@root/shared/utils/notification';

import { ExpertOrder, ModifyTradeForm } from '../components/details/trades-and-history/modify-trade-modal';
import { CreateExpertDtoMapper } from '../mappers/create-expert-dto.mapper';
import { updateExpertOrder } from '../services/update-experts-order.service';
import { createExpertValidation } from '../validations/common.validation';

type Props = {
  order: ExpertOrder;
  closeModal?: () => void;
};

export const useModifyTradeForm = ({ order, closeModal }: Props) => {
  const { t } = useTranslation('forex-experts');
  const { t: yupT } = useTranslation('yup');

  const isMagic = isMagicOrder(order);

  const initialValues = useMemo<ModifyTradeForm>(() => {
    let partialAllowed = true;

    if (order.additionalDetails?.options?.partialClose?.level > 0) {
      partialAllowed = false;
    }

    const expertConfiguration = CreateExpertDtoMapper.toDomain(order.expertConfiguration);

    return {
      ...expertConfiguration,
      partialAllowed,
    };
  }, [order]);

  const onSubmit = useCallback(
    async (values: ModifyTradeForm, helpers: FormikHelpers<ModifyTradeForm>) => {
      helpers.setSubmitting(true);

      const expert = CreateExpertDtoMapper.toPersistence(values);

      const data = {
        breakEven: expert.breakEven,
        partialClose: expert.partialClose,
        trailStop: expert.trailStop,
      };

      const response = await updateExpertOrder(order.expertConfiguration.id, order.ticket, order.accountId, order.ticket, isMagic, data);

      if (response.status === 200) {
        const expertId = order.expertConfiguration.id;
        queryClient.invalidateQueries(['fx', expertId]);
        queryClient.invalidateQueries(['fx', order.accountId, 'open-orders']);
        notify({
          type: 'success',
          title: t('tradeUpdated'),
        });
      } else {
        notify({
          type: 'danger',
          title: response.payload,
        });
      }

      closeModal?.();

      helpers.setSubmitting(false);
    },
    [order.expertConfiguration.id, order.ticket, order.accountId, order.symbol, isMagic, closeModal, t],
  );

  return {
    initialValues,
    onSubmit,
    validationSchema: createExpertValidation(t, yupT, isMagic),
  };
};
