import clsx from 'clsx';
import { useFormikContext } from 'formik';
import { FC, useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { CreateExpertDto } from '@root/modules/experts/dtos/create-expert.dto';
import { useProviderTraders } from '@root/modules/marketplace/hooks/use-provider-traders';
import { authSelector } from '@root/shared-files/modules/auth/store';
import { SelectField, TextField, TextareaField } from '@root/shared/form';
import { CheckboxField } from '@root/shared/form';
import { InfoTooltipIcon } from '@root/shared/icons/info-tooltip-icon';
import { IconLabel } from '@root/shared/ui/form';
import { ImageWithFallback } from '@root/shared/ui/image/image-with-fallback';
import { InfoTooltip } from '@root/shared/ui/info-tooltip';
import { Text, Title } from '@root/shared/ui/typography';
import { checkIsPersonalWebhookAvailable } from '@root/shared/utils/helpers/check-is-personal-webhook-available';

import { useCreateExpertFieldOptions } from '../hooks/use-create-expert-options';

type Props = {
  isPartialEdit?: boolean;
};

export const Trigger: FC<Props> = ({ isPartialEdit }) => {
  const { t } = useTranslation('forex-experts');
  const { values, setFieldValue, touched } = useFormikContext<CreateExpertDto>();
  const { traders } = useProviderTraders();
  const subscriptionInfo = useSelector(authSelector.getSubscriptionInfo);
  const options = useCreateExpertFieldOptions();

  const isSignalStrategyType = values.strategy.type === 'signal';
  const isManualStrategyType = values.strategy.type === 'manual';
  const isPersonalWebhookAvailable = checkIsPersonalWebhookAvailable(subscriptionInfo?.roles);

  const providerOptions = useMemo(
    () =>
      traders.map((item) => ({
        value: item.providerId,
        label: item.name,
        prefix: (
          <div>
            <ImageWithFallback width={16} height={16} label={item.name?.substring(2)} />
          </div>
        ),
      })),
    [traders],
  );

  const strategyTypeOptions = useMemo(() => {
    if (!isPersonalWebhookAvailable) {
      return [options.strategyTypeOptions[0]];
    }

    return options.strategyTypeOptions;
  }, [options.strategyTypeOptions, isPersonalWebhookAvailable]);

  useEffect(() => {
    if (values.strategy.type === 'signal' && values.providerId) {
      const selectedProvider = traders?.find(({ id }) => id === values.providerId);
      if (selectedProvider) setFieldValue('usePips', selectedProvider.market === 'forex' || values.symbols.includes('XAUUSD'));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.providerId, setFieldValue, values.strategy?.type, values.symbols]);

  return (
    <div>
      {!isPartialEdit && (
        <Title level={7} className='mb-4'>
          {t('steps.trigger.title')}
        </Title>
      )}

      <SelectField
        name='strategy.type'
        label={t('fields.strategyTypeWebhook.label')}
        wrapperClassName={clsx({ 'mb-4': !isPartialEdit, 'mb-2': isPartialEdit })}
        placeholder={t('fields.strategyTypeWebhook.placeholder')}
        options={strategyTypeOptions}
        onChange={(e) => {
          setFieldValue('strategy.type', e.value);
          if (e.value === 'manual' && !touched.manualSlTp?.tpRatio) {
            setFieldValue('manualSlTp.tpRatio', 1);
          }
        }}
      />

      {isSignalStrategyType && (
        <div className={clsx({ 'mb-4': !isPartialEdit, 'mb-2': isPartialEdit })}>
          <SelectField name='providerId' label={t('fields.providerId.label')} options={providerOptions} placeholder={t('fields.providerId.placeholder')} />
          {!traders.length && (
            <Text className='text-warning-500 text-right' size='sm'>
              <Trans i18nKey='forex-experts:fields.providerId.noTISubs' components={{ a: <Link to={'/marketplace'} className='text-primary-400 hover:text-primary-400' /> }} />
            </Text>
          )}
        </div>
      )}
      {isManualStrategyType && (
        <Text size='sm' className='-mt-2 mb-3 text-warning-500'>
          {t('fields.strategyTypeWebhook.warning')}
        </Text>
      )}
      <TextField name='name' label={t('fields.name.label')} placeholder={t('fields.name.placeholder')} wrapperClassName={clsx({ 'mb-4': !isPartialEdit, 'mb-2': isPartialEdit })} />
      <TextareaField
        name='description'
        label={t('fields.description.label')}
        maxLength={200}
        placeholder={t('fields.description.placeholder')}
        wrapperClassName={clsx({ 'mb-4': !isPartialEdit, 'mb-2': isPartialEdit })}
      />

      <div className='flex flex-col gap-4 w-full mt-0 mb-4'>
        <Text size='sm' className='-mb-2'>
          {t('fields.conditionalClosure.use.label')}
        </Text>
        <div className='flex flex-col align-center gap-2'>
          <CheckboxField
            type='radio'
            name='conditionalClosure.use'
            label={
              <IconLabel
                icon={
                  <InfoTooltip content={<Text size='sm'>{t('fields.conditionalClosure.use.options.notSelected.hint')}</Text>}>
                    <InfoTooltipIcon />
                  </InfoTooltip>
                }
              >
                <Text>{t('fields.conditionalClosure.use.options.notSelected.label')}</Text>
              </IconLabel>
            }
            checked={!values.conditionalClosure.use}
            onChange={() => setFieldValue('conditionalClosure.use', false)}
          />
          <CheckboxField
            type='radio'
            name='conditionalClosure.use'
            label={
              <IconLabel
                icon={
                  <InfoTooltip content={<Text size='sm'>{t('fields.conditionalClosure.use.options.selected.hint')}</Text>}>
                    <InfoTooltipIcon />
                  </InfoTooltip>
                }
              >
                <Text>{t('fields.conditionalClosure.use.options.selected.label', { point: values.unitType })}</Text>
              </IconLabel>
            }
            checked={values.conditionalClosure.use}
            onChange={() => setFieldValue('conditionalClosure.use', true)}
          />
        </div>
        {values.conditionalClosure.use && (
          <TextField
            name='conditionalClosure.amount'
            label={t('fields.conditionalClosure.amount.label')}
            placeholder={t('fields.conditionalClosure.amount.placeholder')}
            wrapperClassName={clsx({ 'mb-4': !isPartialEdit, 'mb-2': isPartialEdit })}
            suffix={values.unitType}
          />
        )}
      </div>
    </div>
  );
};
