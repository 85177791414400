import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ProvidersTableRow } from '@root/modules/marketplace/components/providers-table-row';
import { MarketPlaceTradeIdeaItem } from '@root/modules/marketplace/types/marketplace-trade-idea';
import { PageLinks } from '@root/shared/constants/pageLinks';
import { InfoTooltipIcon } from '@root/shared/icons/info-tooltip-icon';
import { NoDataFound } from '@root/shared/ui/common';
import { IconLabel } from '@root/shared/ui/form';
import { InfoTooltip } from '@root/shared/ui/info-tooltip';
import { SkeletonTable } from '@root/shared/ui/skeleton-table/skeleton-table';
import { Table } from '@root/shared/ui/table';
import { Text } from '@root/shared/ui/typography';

import { useTradeIdeaSubscription } from '../hooks/use-trade-idea-provider-subscription';
import { MarketPlaceTradeIdeaSubscribeModal } from './marketpalce-trade-idea-subscribe-modal';
import { MarketplaceRetryPaymentModal } from './marketplace-retry-payment-modal';
import { MarketPlaceTradeIdeaUnsubscribeModal } from './marketplace-trade-idea-unsubscribe-modal';

const { Head, Row } = Table;

export const ids = ['8cadc066-1b4e-480a-941a-bf0d34897f11', '591b8111-7271-4586-9cba-f666ca2209c2', 'cc1536b3-8fa4-45a1-8137-94b66b59faf8'];

interface IProps {
  list?: MarketPlaceTradeIdeaItem[];
  isLoading?: boolean;
  subscriptionType: 'month' | 'year';
}

export const MarketplaceTradeIdeaProvidersTable: FC<IProps> = ({ list, isLoading = false, subscriptionType }) => {
  const { t } = useTranslation('marketplace-trade-idea-providers');
  const { t: tEaGuide } = useTranslation('ea-guide', {
    keyPrefix: 'form.fields.tradeProviders.table',
  });

  const navigate = useNavigate();

  const [
    { selectedProvider, loading, isUnsubscribeOpen, isSubscribeOpen, retryPaymentLoadingId, retryPaymentSubscription },
    { handleSubscribeClick, handleUnsubscribeClick, onCancel, setSelectedProvider, handleRetryPayment, retryModalOk, setRetrySubscription },
  ] = useTradeIdeaSubscription({
    subscriptionType,
  });

  const handleConnectAssistClick = useCallback(
    (item) => {
      navigate(`${PageLinks.createExpert}?providerId=${item.id}`);
    },
    [navigate],
  );

  if (!list?.length && !isLoading) {
    return (
      <div className='py-20'>
        <NoDataFound />
      </div>
    );
  }

  return (
    <div className='px-5'>
      <Table.Wrapper>
        <colgroup>
          <col style={{ width: 280, minWidth: 200 }} />
          <col />
          <col />
          <col />
          <col />
          <col />
          <col style={{ minWidth: 180 }} />
        </colgroup>

        <Head>
          <Row>
            <Table.HeadCell>{tEaGuide('columns.name.title')}</Table.HeadCell>
            <Table.HeadCell>
              <div className='text-sm p-2'>{tEaGuide('columns.wlRatio.header')}</div>
            </Table.HeadCell>
            {/* <Table.HeadCell>
              <IconLabel
                icon={
                  <InfoTooltip
                    content={
                      <div className='text-sm p-2'>
                        <Text className='font-bold mb-2'>{tEaGuide('columns.profitFactor.title')}</Text>
                        <Text className='text-gray-500'>{tEaGuide('columns.profitFactor.description')}</Text>
                      </div>
                    }
                  >
                    <InfoTooltipIcon />
                  </InfoTooltip>
                }
              >
                {tEaGuide('columns.profitFactor.title')}
              </IconLabel>
            </Table.HeadCell> */}

            <Table.HeadCell>{tEaGuide('columns.avgDuration.title')}</Table.HeadCell>
            <Table.HeadCell>{tEaGuide('columns.pricing.title')}</Table.HeadCell>
            <Table.HeadCell> {t('table.subscribersCount')}</Table.HeadCell>
            <Table.HeadCell className='text-right'>{t('table.actions')}</Table.HeadCell>
          </Row>
        </Head>

        <Table.Body>
          {isLoading ? (
            <SkeletonTable rows={10} columns={8} />
          ) : (
            list?.map((item) => (
              <ProvidersTableRow
                key={item.id}
                item={item}
                ids={ids}
                handleConnectAssistClick={handleConnectAssistClick}
                setSelectedProvider={setSelectedProvider}
                retryPaymentLoadingId={retryPaymentLoadingId}
                handleRetryPayment={handleRetryPayment}
                subscriptionType={subscriptionType}
                handleSubscribeClick={handleSubscribeClick}
                loading={loading}
              />
            ))
          )}
        </Table.Body>
      </Table.Wrapper>
      {!!retryPaymentSubscription && (
        <MarketplaceRetryPaymentModal isOpen={!!retryPaymentSubscription} loading={!!retryPaymentLoadingId} onCancel={() => setRetrySubscription(null)} onOk={retryModalOk} />
      )}
      {isSubscribeOpen && (
        <MarketPlaceTradeIdeaSubscribeModal
          marketType={(selectedProvider as MarketPlaceTradeIdeaItem)?.market}
          isOpen={isSubscribeOpen}
          onCancel={onCancel}
          onOk={() => handleSubscribeClick()}
          loading={loading}
        />
      )}
      {isUnsubscribeOpen && <MarketPlaceTradeIdeaUnsubscribeModal isOpen={isUnsubscribeOpen} onCancel={onCancel} onOk={handleUnsubscribeClick} loading={loading} />}
    </div>
  );
};
