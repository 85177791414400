import { MainOrderType } from '@root/modules/orders/enums/orders';

export const isSellOrder = (type) => type === 'Sell' || type === 'Sell Limit' || type === 'Sell Stop';
export const isBuyOrder = (type) => type === 'Buy' || type === 'Buy Limit' || type === 'Buy Stop';
export const isLimitOrder = (type) => ['Buy Limit', 'Sell Limit', 'Buy Stop', 'Sell Stop'].includes(type);

export const gerOrderType = (type): string => {
  switch (type) {
    case MainOrderType.BUY:
      return 'Buy';
    case MainOrderType.SELL:
      return 'Sell';
    case MainOrderType.BUY_LIMIT:
      return 'Buy Limit';
    case MainOrderType.SELL_LIMIT:
      return 'Sell Limit';
    case MainOrderType.BUY_STOP:
      return 'Buy Stop';
    case MainOrderType.SELL_STOP:
      return 'Sell Stop';
    default:
      return '-';
  }
};

export const isMagicOrder = (order) => order.signalId === 'magic' || order.comment === 'magic';
