import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IAccount } from '@root/modules/accounts';
import { useGetAccounts } from '@root/modules/accounts/hooks/use-get-accounts';
import { useGetOpenOrders } from '@root/modules/orders/hooks/use-open-orders';
import { quotesSelector } from '@root/modules/quotes/store/quotes.selector';
import { quotesSlice } from '@root/modules/quotes/store/quotes.slice';

export const useQuoteSubscriber = (selectedBroker = 'all', enabled?: boolean) => {
  const dispatch = useDispatch();
  const { data: accounts } = useGetAccounts();
  const { openOrders } = useGetOpenOrders({ enabled, selectedBroker, mtType: undefined });
  const subscribedSymbols = useSelector(quotesSelector.subscribedSymbols);

  const brokerIdByAccountId = useMemo(() => {
    return (accounts || ([] as IAccount[]))?.reduce((acc, item) => {
      acc[item.id] = item.companyId;
      return acc;
    }, {});
  }, [accounts]);

  const toSubscribeKeyList = openOrders.map((item) => `quote-${brokerIdByAccountId?.[item?.accountId]}-${item.symbol}`);

  useEffect(() => {
    const toSubscribeKeys = toSubscribeKeyList.filter((key) => !subscribedSymbols.includes(key));

    if (toSubscribeKeys?.length) {
      dispatch(quotesSlice.actions.subscribe(toSubscribeKeys));
    }
  }, [dispatch, toSubscribeKeyList, subscribedSymbols]);

  useEffect(() => {
    return () => {
      dispatch(quotesSlice.actions.unsubscribeAll());
    };
  }, [dispatch]);
};
