import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetAccounts } from '@root/modules/accounts/hooks/use-get-accounts';
import { CustomErrorCodes } from '@root/shared/ui/toast/custom-errors';

export const useCustomErrors = (customData) => {
  const { data } = useGetAccounts();
  const { t } = useTranslation('errors');

  const message = useMemo(() => {
    if (!customData) {
      return null;
    }

    switch (customData.code) {
      case CustomErrorCodes.InvalidAccount: {
        const username = data.find((account) => account.id === customData.accountId)?.username;
        return t(customData.code, { userName: username });
      }

      case CustomErrorCodes.ReachedExpertsLimit:
      case CustomErrorCodes.WrongAccountCredentials:
      case CustomErrorCodes.ReachedAccountsLimit:
      case CustomErrorCodes.AccountAlreadyExist: {
        return t(customData.code);
      }

      case CustomErrorCodes.UnhandledException: {
        return t(customData.code, { requestId: customData.payload.errorId });
      }

      default: {
        return null;
      }
    }
  }, [customData, data, t]);

  return { message };
};
