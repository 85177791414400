import { CaseReducer, PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IOrder } from '@root/modules/orders/types/orders';

export type IOrdersState = {
  orders: Record<string, IOrder>;
  shouldListenOrders: boolean;
  closedOrders: Array<number>;
};

export namespace OrdersActions {
  export type Subscribe = PayloadAction;
  export type UnSubscribe = PayloadAction;
  export type SetOrdersData = PayloadAction<Record<string, IOrder>>;
  export type SetClosedOrders = PayloadAction<number>;
}

export type OrdersSliceReducer = {
  subscribe: CaseReducer<IOrdersState, OrdersActions.Subscribe>;
  unsubscribe: CaseReducer<IOrdersState, OrdersActions.UnSubscribe>;
  setOrdersData: CaseReducer<IOrdersState, OrdersActions.SetOrdersData>;
  setClosedOrders: CaseReducer<IOrdersState, OrdersActions.SetClosedOrders>;
};

const initialState: IOrdersState = {
  orders: {},
  shouldListenOrders: false,
  closedOrders: [],
};

export const ordersSlice = createSlice<IOrdersState, OrdersSliceReducer, 'orders'>({
  name: 'orders',
  initialState,
  reducers: {
    subscribe: (state) => {
      state.shouldListenOrders = true;
    },
    unsubscribe: (state) => {
      state.shouldListenOrders = false;
    },
    setOrdersData: (state, action) => {
      state.orders = action.payload;
    },
    setClosedOrders: (state, action) => {
      state.closedOrders = [...state.closedOrders, action.payload];
    },
  },
});
