import { FxBreakevenType, FxPartialCloseType, FxPriceToleranceType, FxSessionManagementActionType, FxTpProximityType, FxTradingSession } from '@3lgn/shared/dist/constants/fx';

import { ExpertOrderDirection, ExpertOrderType, TemplateUnitType } from '@root/modules/experts/types/common';

import { CreateExpertDto } from '../dtos/create-expert.dto';
import { IExpert } from '../types/expert';

export class CreateExpertDtoMapper {
  public static toDomain(expert: Omit<IExpert, 'isEnabled'>): Omit<CreateExpertDto, 'meta'> {
    const signalSlTp: Partial<CreateExpertDto['signalSlTp']> = {};
    const partialClose: Partial<CreateExpertDto['partialClose']> = {};

    if ([4, 5].includes(expert.signalSlTp?.type)) {
      signalSlTp.type = '4';
      if (expert.signalSlTp.type === 5) {
        signalSlTp.slType = '1';
      } else {
        signalSlTp.slType = '0';
      }

      if (expert.signalSlTp.tpRatio) {
        signalSlTp.profitCalculationType = '0';
        signalSlTp.fixedTp = '0';
      } else {
        signalSlTp.profitCalculationType = '1';
        signalSlTp.fixedTp = expert.signalSlTp.fixedTp?.toString() || '0';
      }
    }

    if (expert.partialClose.type === FxPartialCloseType.SMART) {
      partialClose.calculationType = '1';
    } else {
      partialClose.calculationType = '0';
    }

    return {
      id: expert.id,
      name: expert.name,
      description: expert?.description || '',
      account: expert.accountId || '',
      providerId: expert.providerId || '',
      expertPresetId: expert.expertPresetId,
      symbols: expert.symbols,
      magicNumber: expert.magicNumber,
      useSlippage: expert.slippage?.toString() !== '0',
      slippage: expert.slippage?.toString(),
      usePips: expert.usePips,
      unitType: expert.usePips ? TemplateUnitType.PIPS : TemplateUnitType.POINTS,
      maxManualActiveTrades: expert.maxManualActiveTrades?.toString() || '1',
      maxSignalActiveTrades: expert.maxSignalActiveTrades?.toString() || '1',
      allowClone: expert.allowClone,
      allowShare: expert.allowShare,
      cloneFromExpertId: expert.cloneFromExpertId,
      strategy: {
        type: (expert.useSignalTrades && 'signal') || (expert.useManualTrades && 'manual') || '',
        usePriceTolerance: expert?.priceTolerance !== 0,
        priceTolerance: expert.priceTolerance?.toString(),
        priceToleranceType: expert.priceToleranceType === FxPriceToleranceType.PERCENT ? '1' : '0',
        timeTolerance: Number(expert.timeTolerance || 0) % 60 === 0 ? (Number(expert.timeTolerance) / 60).toString() : expert.timeTolerance.toString(),
        timeToleranceType: Number(expert.timeTolerance || 0) % 60 === 0 ? '1' : '0',
        usePostSignalProximity: expert.tpProximity !== 0,
        tpProximity: expert.tpProximity?.toString(),
        tpProximityType: expert?.tpProximityType === FxTpProximityType.PERCENT ? '1' : '0',
      },
      manualMoneyManagement: {
        type: expert.manualMoneyManagement.type?.toString(),
        lotsize: expert.manualMoneyManagement.lotsize?.toString(),
        riskPercent: expert.manualMoneyManagement.riskPercent?.toString(),
        basedOn: expert.manualMoneyManagement.basedOn?.toString(),
        k: expert.manualMoneyManagement.k?.toString(),
      },
      signalMoneyManagement: {
        type: expert.signalMoneyManagement?.type?.toString(),
        lotsize: expert.signalMoneyManagement?.lotsize?.toString(),
        riskPercent: expert.signalMoneyManagement?.riskPercent?.toString(),
        basedOn: expert.signalMoneyManagement?.basedOn?.toString(),
        k: expert.signalMoneyManagement?.k?.toString(),
        xPercent: expert.signalMoneyManagement?.xPercent?.toString(),
      },
      maxDrawDownLimit: expert.maxDrawDownLimit
        ? {
            use: !!expert.maxDrawDownLimit.percent,
            percent: expert.maxDrawDownLimit.percent?.toString(),
            basedOn: expert.maxDrawDownLimit.basedOn?.toString(),
            period: expert.maxDrawDownLimit.period?.toString(),
          }
        : {
            use: false,
            percent: '',
            basedOn: '0',
            period: '0',
          },
      hitMaxDrawDownLimitUntil: expert.hitMaxDrawDownLimitUntil,
      breakEven: {
        use: expert.breakEven.use,
        type: [FxBreakevenType.PIPS, FxBreakevenType.PERCENT].includes(expert.breakEven.type as FxBreakevenType)
          ? expert.breakEven.type === FxBreakevenType.PERCENT
            ? '1'
            : '0'
          : null,
        levels: expert.breakEven.levels.map((item) => ({
          afterX: item.afterX?.toString(),
          setToY: item.setToY?.toString(),
        })),
      },
      trailStop: {
        use: expert.trailStop.use,
        afterX: expert.trailStop.afterX?.toString(),
        followY: expert.trailStop.followY?.toString(),
      },
      partialClose: {
        use: expert.partialClose.use,
        calculationType: '0',
        takeprofits: expert.partialClose.takeprofits.map((item) => ({
          amount: item.amount?.toString(),
          percent: item.percent?.toString(),
        })),
        ...partialClose,
      },
      conditionalClosure: {
        use: expert.conditionalClosure?.use,
        amount: expert.conditionalClosure?.amount?.toString(),
      },
      manualSlTp: {
        type: expert.manualSlTp.type?.toString(),
        fixedSl: expert.manualSlTp.fixedSl?.toString(),
        fixedTp: expert.manualSlTp.fixedTp?.toString(),
        tpRatio: expert.manualSlTp.tpRatio,
        riskRatio: expert.manualSlTp.riskRatio || 1,
      },
      signalSlTp: {
        type: expert.signalSlTp?.type?.toString(),
        fixedSl: expert.signalSlTp?.fixedSl?.toString(),
        fixedTp: expert.signalSlTp?.fixedTp?.toString(),
        tpRatio: expert.signalSlTp?.tpRatio,
        riskRatio: expert.signalSlTp?.riskRatio || 1,
        profitCalculationType: '0',
        slType: '0',
        ...signalSlTp,
      },
      sessionManagement: expert.sessionManagement
        ? {
            use: !!expert.sessionManagement.use,
            type: expert.sessionManagement.type,
            sessions:
              Object.values(FxTradingSession).every((enumValue) => expert.sessionManagement?.sessions.includes(enumValue)) || !expert.sessionManagement.sessions.length
                ? ['all']
                : expert.sessionManagement?.sessions,
            threshold: expert.sessionManagement.threshold?.toString(),
          }
        : {
            use: false,
            type: FxSessionManagementActionType.LEAVE_RUNNING,
            sessions: ['all'],
            threshold: '',
          },
      orderType: expert.orderType,
      orderDirection: expert.orderDirection,
    };
  }

  public static toPersistence({ id, ...values }: Omit<CreateExpertDto, 'meta'>): Omit<IExpert, 'id' | 'isEnabled' | 'createdAt'> {
    const signalSlTp: Partial<IExpert['signalSlTp']> = {};

    if (values.signalSlTp.type === '4') {
      signalSlTp.type = parseFloat(values.signalSlTp.slType === '0' ? '4' : '5');

      if (values.signalSlTp.profitCalculationType === '0') {
        signalSlTp.fixedTp = 0;
        signalSlTp.tpRatio = values.signalSlTp.tpRatio || 0;
        signalSlTp.riskRatio = values.signalSlTp.riskRatio || 1;
      } else {
        signalSlTp.tpRatio = 0;
        signalSlTp.riskRatio = 1;
        signalSlTp.fixedTp = parseFloat(values.signalSlTp.fixedTp) || 0;
      }
    }

    return {
      name: values.name,
      description: values?.description === '' ? null : values?.description || undefined,
      slippage: values.useSlippage ? parseFloat(values.slippage) : 0,
      symbols: values.symbols,
      providerId: values.strategy.type === 'signal' ? values.providerId : null,
      expertPresetId: values.expertPresetId || null,
      accountId: values.account,
      magicNumber: values.magicNumber,
      usePips: values.usePips,
      useManualTrades: values.strategy.type === 'manual',
      useSignalTrades: values.strategy.type === 'signal',
      allowClone: values.allowClone,
      allowShare: values.allowShare,
      cloneFromExpertId: values.cloneFromExpertId,
      timeTolerance:
        values.strategy.type === 'signal'
          ? values.strategy.usePriceTolerance
            ? values.strategy.timeToleranceType === '1'
              ? parseFloat(values.strategy.timeTolerance) * 60
              : parseFloat(values.strategy.timeTolerance)
            : 0
          : 0,
      maxManualActiveTrades: parseInt(values.maxManualActiveTrades),
      maxSignalActiveTrades: parseInt(values.maxSignalActiveTrades),
      priceTolerance: values.strategy.type === 'signal' && values.strategy.usePriceTolerance ? parseFloat(values.strategy.priceTolerance) : 0,
      priceToleranceType: values.strategy.priceToleranceType === '0' ? FxPriceToleranceType.PIPS : FxPriceToleranceType.PERCENT,
      tpProximity: values.strategy.type === 'signal' && values.strategy.usePostSignalProximity ? parseFloat(values.strategy.tpProximity) : 0,
      tpProximityType: values.strategy.tpProximityType === '0' ? FxTpProximityType.PIPS : FxTpProximityType.PERCENT,
      manualMoneyManagement: {
        type: parseFloat(values.manualMoneyManagement.type),
        lotsize: parseFloat(values.manualMoneyManagement.lotsize),
        riskPercent: parseFloat(values.manualMoneyManagement.riskPercent),
        basedOn: parseFloat(values.manualMoneyManagement.basedOn),
        k: parseFloat(values.manualMoneyManagement.k),
      },
      signalMoneyManagement: {
        type: parseFloat(values.signalMoneyManagement.type),
        lotsize: parseFloat(values.signalMoneyManagement.lotsize),
        riskPercent: parseFloat(values.signalMoneyManagement.riskPercent),
        basedOn: parseFloat(values.signalMoneyManagement.basedOn),
        k: parseFloat(values.signalMoneyManagement.k),
        xPercent: parseFloat(values.signalMoneyManagement.xPercent),
      },
      breakEven: {
        use: values.breakEven.use,
        levels: values.breakEven.use
          ? values.breakEven.levels.map((item) => ({
              afterX: parseFloat(item.afterX),
              setToY: parseFloat(item.setToY),
            }))
          : [],
        type: values.breakEven.type === '1' ? FxBreakevenType.PERCENT : FxBreakevenType.PIPS,
      },
      trailStop: {
        use: values.trailStop.use,
        afterX: parseFloat(values.trailStop.afterX),
        followY: parseFloat(values.trailStop.followY),
      },
      partialClose: {
        use: values.partialClose.use,
        type: values.partialClose.calculationType === '1' ? FxPartialCloseType.SMART : FxPartialCloseType.CLASSIC,
        takeprofits: values.partialClose.use
          ? values.partialClose.takeprofits.map((item) => ({
              amount: parseFloat(item.amount),
              percent: parseFloat(item.percent),
            }))
          : [],
      },
      conditionalClosure: {
        use: values.conditionalClosure.use,
        amount: Number(values.conditionalClosure.amount) || 0,
      },
      maxDrawDownLimit: values.maxDrawDownLimit?.use
        ? {
            percent: Number(values.maxDrawDownLimit.percent),
            basedOn: parseFloat(values.maxDrawDownLimit.basedOn),
            period: parseFloat(values.maxDrawDownLimit.period),
          }
        : null,
      sessionManagement: values.sessionManagement?.use
        ? {
            use: values.sessionManagement.use,
            sessions: values.sessionManagement.sessions.includes('all') ? Object.values(FxTradingSession).map((enumValue) => enumValue) : values.sessionManagement.sessions,
            type: values.sessionManagement.type,
            threshold: values.sessionManagement.type === FxSessionManagementActionType.CLOSE_IF_IN_PROFIT ? parseFloat(values.sessionManagement.threshold || '0') : undefined,
          }
        : null,
      manualSlTp: {
        type: parseFloat(values.manualSlTp.type),
        fixedSl: parseFloat(values.manualSlTp.fixedSl),
        fixedTp: parseFloat(values.manualSlTp.fixedTp),
        tpRatio: values.manualSlTp.type === '0' ? 0 : parseFloat(values?.manualSlTp?.tpRatio as unknown as string) || 0,
        riskRatio: values.manualSlTp.riskRatio || 1,
      },
      signalSlTp: {
        type: parseFloat(values.signalSlTp.type),
        fixedSl: parseFloat(values.signalSlTp.fixedSl) || 0,
        fixedTp: parseFloat(values.signalSlTp.fixedTp) || 0,
        tpRatio: parseFloat(values.signalSlTp.tpRatio as unknown as string) || 0,
        riskRatio: values.signalSlTp.riskRatio || 1,
        ...signalSlTp,
      },
      orderType: values.orderType || ExpertOrderType.NEUTRAL,
      orderDirection: values.orderDirection || ExpertOrderDirection.NEUTRAL,
    };
  }
}
