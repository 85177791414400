import { MutationFunction } from 'react-query';

import type { IHttpResponse } from '@root/shared/utils/http-service';

import { SignInAccountServiceResponse, SignInAccountServiceValues, signInAccountService } from '../services/sign-in-account.service';

export type SignInAccountMutationData = SignInAccountServiceResponse;
export type SignInAccountMutationValues = SignInAccountServiceValues;
export type SignInAccountMutationError = IHttpResponse<400, string>;

export const SIGN_IN_ACCOUNT_MUTATION: MutationFunction<SignInAccountMutationData, SignInAccountMutationValues> = async (values) => {
  return await signInAccountService(values);
};
