import clsx from 'clsx';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { useGetAccountCurrency } from '@root/modules/accounts/hooks/use-get-account-currency';
import { DashboardStatsFilters } from '@root/modules/dashboard/components/dashboard-stats-filters';
import { GET_EXPERT_STATS_DATA } from '@root/modules/experts/queries/get-expert-stats-data.query';
import { IExpert } from '@root/modules/experts/types/expert';
import { StatisticTable } from '@root/modules/orders/components/statistic-table';
import { TradeStat } from '@root/modules/orders/components/trade-statistic';
import { useGetSelectedStatFilters } from '@root/modules/orders/contexts/stat-filters.context';
import { GET_USER_STATS_DATA, createKey } from '@root/modules/orders/queries/get-users-stats-data.query';
import { FullStatsData, TradeStats } from '@root/modules/orders/types/statistic';
import { authSelector } from '@root/shared-files/modules/auth/store';
import { ChevronIcon } from '@root/shared/icons/chevron-icon';
import { Text } from '@root/shared/ui/typography';

type Props = {
  expert: IExpert;
};

export const DetailedStats: FC<Props> = ({ expert }) => {
  const userId = useSelector(authSelector.userId);

  const [statVisible, setStatVisible] = useState<boolean>(false);
  const { t } = useTranslation('forex-experts');
  const { isCustomRange, fromDate, toDate } = useGetSelectedStatFilters();

  const { currencySymbol } = useGetAccountCurrency(expert.accountId);

  const { data, isLoading } = useQuery(['fx', { id: expert.id }, 'expert-statistic'], GET_EXPERT_STATS_DATA, {
    enabled: !!expert.id,
    staleTime: 1000 * 60 * 10,
  });

  const { data: fullStatData, isLoading: isFullStatDataLoading } = useQuery(
    createKey({ accounts: expert.accountId, fromDate: fromDate?.toISOString(), toDate: toDate?.toISOString(), experts: [expert.id] }),
    GET_USER_STATS_DATA,
    {
      enabled: !!userId && statVisible,
      staleTime: 1000 * 60 * 10,
    },
  );

  const { stats, ...rest } = fullStatData || {};
  const tradeStatData = isCustomRange && fromDate && toDate ? stats : data;

  return (
    <div className='w-full px-5 py-2.5 bg-gray-800 rounded'>
      <div className='flex items-center gap-2 text-light-100 cursor-pointer' onClick={() => setStatVisible((prev) => !prev)}>
        <ChevronIcon className={clsx('transform transition-500', { 'rotate-180': !statVisible })} />
        <Text className='font-semibold'>{t('detailedStat')}</Text>
      </div>
      {data && statVisible && (
        <div className='flex flex-col gap-y-2 mb-2.5'>
          <div className='w-full flex  lg:-mt-5 justify-end items-center gap-x-2'>
            <DashboardStatsFilters isExpertDetailsPage />
          </div>
          <StatisticTable
            loading={isLoading}
            data={rest as Omit<FullStatsData, 'stats'>}
            currency={currencySymbol}
            isCustomRange={isCustomRange}
            fromDate={fromDate}
            toDate={toDate}
          />
          <TradeStat loading={isLoading} data={tradeStatData as TradeStats} currency={currencySymbol} />
        </div>
      )}
    </div>
  );
};
