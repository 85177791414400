import { Formik, FormikProps, setNestedObjectValues } from 'formik';
import React, { FC, Fragment, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { OrderOptions } from '@root/modules/experts/components/order-options';
import { useSymbolUpdater } from '@root/modules/experts/hooks/use-symbol-updater';
import { TemplateUnitType } from '@root/modules/experts/types/common';
import { useGhostLogin } from '@root/shared-files/modules/auth/hooks/use-ghost-login';
import { useFormLeave } from '@root/shared/hooks/use-form-leave';
import { Button } from '@root/shared/ui/button';
import { LoadingSection } from '@root/shared/ui/common/loading-section';
import { LeaveModal } from '@root/shared/ui/leave-modal/leave-modal';

import { Accounts } from '../components/accounts';
import { AdvancedSettings } from '../components/advanced-settings';
import { BreakEven } from '../components/break-even';
import { CreateExpertSteps } from '../components/create-expert-steps';
import { CreateWizardExpertHeader } from '../components/create-wizard-expert-header';
import { Drawdown } from '../components/drawdown';
import { EnableExpertModal } from '../components/enable-expert-modal';
import { ManualMoneyManagement } from '../components/manual-money-management';
import { ManualStopLossTakeProfit } from '../components/manual-stop-loss-take-profit';
import { PartialTakeProfits } from '../components/partial-take-profits';
import { Preview } from '../components/preview';
import { SessionManagement } from '../components/session-management';
import { SignalMoneyManagement } from '../components/signal-money-management';
import { SignalStopLossTakeProfit } from '../components/signal-stop-loss-take-profit';
import { TopPresets } from '../components/top-presets';
import { TrailingStop } from '../components/trailing-stop';
import { Trigger } from '../components/trigger';
import { CreateExpertDto } from '../dtos/create-expert.dto';
import { CreateExpertStepKey, useCreateExpertSteps } from '../hooks/use-create-expert-steps';
import { useCreateWizardExpert } from '../hooks/use-create-wizard-expert';
import { expertsSelector } from '../store/experts.selector';
import { expertsSlice } from '../store/experts.slice';

const FormComponent: FC<FormikProps<CreateExpertDto>> = ({ status, handleSubmit, values, setFieldValue, isSubmitting, validateForm, setTouched }) => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  const expertCreated = status?.type === 'success';

  useSymbolUpdater();

  const expertIdToRun = useSelector(expertsSelector.runExpertId);
  const [{ step, canGoPrev, canGoNext }, { onNext, onPrev, onChange }] = useCreateExpertSteps();
  const { isModalOpen, closeModal, handleLeaveClick } = useFormLeave<CreateExpertDto>(!expertCreated);
  const { hideActions } = useGhostLogin();

  const isEdit = !!values.id;

  const handleCloseRunModal = useCallback(() => {
    dispatch(expertsSlice.actions.runExpertClosed());
  }, [dispatch]);

  const touchErrorFields = useCallback(async () => {
    const errors = await validateForm();
    if (Object.keys(errors).length > 0) {
      setTouched(setNestedObjectValues(errors, true));
      return;
    }
  }, [setTouched, validateForm]);

  useEffect(() => {
    setFieldValue('unitType', values.usePips ? TemplateUnitType.PIPS : TemplateUnitType.POINTS);
  }, [values.usePips, setFieldValue]);

  useEffect(() => {
    if (values.meta?.shouldPresetValidate) {
      touchErrorFields();

      setFieldValue('meta.shouldPresetValidate', false);
    }
  }, [setFieldValue, touchErrorFields, values.meta?.shouldPresetValidate]);

  return (
    <form className='w-full' onSubmit={handleSubmit}>
      <CreateWizardExpertHeader />

      <div className='grid grid-cols-12 gap-4'>
        <div className='col-span-12 lg:col-span-3'>
          <CreateExpertSteps shouldValidate value={step} onChange={onChange} />
        </div>

        <div className='col-span-12 lg:col-span-5 xl:col-span-6'>
          <div className='bg-gray-800 p-4 md:p-6 rounded-lg'>
            {step === CreateExpertStepKey.Trigger && <Trigger />}
            {step === CreateExpertStepKey.Account && <Accounts />}
            {step === CreateExpertStepKey.OrderOptions && <OrderOptions />}
            {step === CreateExpertStepKey.MoneyManagement && (
              <Fragment>
                {values.strategy.type === 'manual' && <ManualMoneyManagement type={values.manualMoneyManagement.type} />}
                {values.strategy.type === 'signal' && <SignalMoneyManagement type={values.signalMoneyManagement.type} />}
                <SessionManagement />
                <Drawdown />
              </Fragment>
            )}
            {step === CreateExpertStepKey.BreakEven && <BreakEven use={values.breakEven.use} levels={values.breakEven.levels} />}
            {step === CreateExpertStepKey.StopLossTakeProfit && (
              <Fragment>
                <div className='pb-2'>
                  {values.strategy.type === 'manual' && <ManualStopLossTakeProfit type={values.manualSlTp.type} unitType={values.unitType} />}
                  {values.strategy.type === 'signal' && <SignalStopLossTakeProfit />}
                </div>
                <div className='bg-gray-900 h-2 -ml-7 -mr-7 mt-4'></div>
                <div className='mt-4'>
                  <TrailingStop use={values.trailStop.use} unitType={values.unitType} />
                </div>
              </Fragment>
            )}
            {step === CreateExpertStepKey.PartialTakeProfits && <PartialTakeProfits />}
            {step === CreateExpertStepKey.AdvancedSettings && <AdvancedSettings />}
            {step === CreateExpertStepKey.Preview && <Preview />}

            <div className='flex pt-4 justify-between gap-x-2'>
              <div>
                {canGoPrev && (
                  <Button outlined onClick={onPrev}>
                    {t('prevStep')}
                  </Button>
                )}
              </div>
              <div>
                {canGoNext && <Button onClick={onNext}>{t('nextStep')}</Button>}
                {!canGoNext && (
                  <Button disabled={hideActions} loading={isSubmitting} type='submit'>
                    {t(isEdit ? 'actions.edit' : 'actions.create', { ns: 'forex-experts' })}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className='col-span-12 lg:col-span-4 xl:col-span-3 flex flex-col gap-y-4'>
          {/* <Assistant /> */}
          <TopPresets />
        </div>
      </div>

      <LeaveModal isOpen={isModalOpen} onOk={handleLeaveClick} onCancel={closeModal} />
      <EnableExpertModal closeModal={handleCloseRunModal} isOpen={!!expertIdToRun} />
    </form>
  );
};

export const CreateWizardExpert = () => {
  const { initialValues, onSubmit, validationSchema } = useCreateWizardExpert();

  if (!initialValues) {
    return <LoadingSection />;
  }

  return <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} component={FormComponent} />;
};
