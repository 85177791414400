import styled from 'styled-components';

import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';

import { ErrorPage } from '@root/infra/layout/containers/error';
import { lazyWithRetries } from '@root/infra/router/lazy-with-retries';
import ProtectedRoute from '@root/infra/router/rest-pages-protector';
import { useYupLocale } from '@root/infra/yup/hooks/use-yup-locale';
import { EmptyDDAccounts } from '@root/modules/accounts/components/empty-dd-accounts';
import { ServiceAccountModal } from '@root/modules/accounts/components/service-account-modal';
import { UpdateAccount } from '@root/modules/accounts/containers/update-account';
import { useCheckAccounts } from '@root/modules/accounts/hooks/use-check-accounts';
import { useServiceAccountChecker } from '@root/modules/accounts/hooks/use-service-account-checker';
import SymbolRemapPage from '@root/modules/accounts/pages/symbol-remap';
import { useCheckSharedBotSubscription } from '@root/modules/experts/hooks/use-check-shared-bot-subscription';
import { useCheckPresetsSubscription } from '@root/modules/presets/hooks/use-check-presets-subscription';
import { QuizModal } from '@root/shared-files/modules/auth/components/quiz-modal';
import { CheckTwoFaEnabledModal } from '@root/shared-files/modules/auth/components/two-fa/check-two-fa-enabled';
import { ResetTwoFaModal } from '@root/shared-files/modules/auth/components/two-fa/reset-two-fa-modal';
import { useCheckGhostLogin } from '@root/shared-files/modules/auth/hooks/use-check-ghost-login';
import { useGhostLogin } from '@root/shared-files/modules/auth/hooks/use-ghost-login';
import { authSelector } from '@root/shared-files/modules/auth/store';
import withMaintenanceMode from '@root/shared-files/modules/maintenance/hocs/with-maintenance';
import { useRefreshSession } from '@root/shared-files/modules/subscriptions/hooks/use-refresh-session';
import { LoadingScreen } from '@root/shared/ui/common';

import { GhostLoginWrapper } from '../layout/containers/ghost-login-wrapper';

const ShareAssist = lazyWithRetries(() => import('@root/modules/experts/pages/share-assist').then(({ ShareAssist }) => ({ default: ShareAssist })));
const LegalsRouter = lazyWithRetries(() => import('@root/modules/legals/router/legals.router').then(({ LegalsRouter }) => ({ default: LegalsRouter })));
const AuthRouter = lazyWithRetries(() => import('@root/shared-files/modules/auth/router/auth.router').then(({ AuthRouter }) => ({ default: AuthRouter })));
const SettingsPage = lazyWithRetries(() => import('@root/modules/user-settings/pages/settings'));
const SubscriptionsPage = lazyWithRetries(() => import('@root/shared-files/modules/subscriptions/pages'));
const SubscriptionPaymentPage = lazyWithRetries(() => import('@root/shared-files/modules/subscriptions/pages/id'));

// FX
const ExpertsRouter = lazyWithRetries(() => import('@root/modules/experts').then((index) => ({ default: index.ExpertModule.router })));
const EAGuideRouter = lazyWithRetries(() => import('@root/modules/ea-guide').then((index) => ({ default: index.EAGuideModule.router })));
const AccountsRouter = lazyWithRetries(() => import('@root/modules/accounts').then((module) => ({ default: module.AccountsModule.router })));
const PresetsRouter = lazyWithRetries(() => import('@root/modules/presets').then((module) => ({ default: module.PresetsModule.router })));
const ExternalScannersRouter = lazyWithRetries(() => import('@root/modules/external-scanners').then((module) => ({ default: module.ExternalScannersModule.router })));
// Dashboard
const DashboardRouter = lazyWithRetries(() => import('@root/modules/dashboard').then((module) => ({ default: module.DashboardModule.router })));
const MarketPlaceRouter = lazyWithRetries(() => import('@root/modules/marketplace').then((module) => ({ default: module.MarketplaceModule.router })));
const MagicTerminalRouter = lazyWithRetries(() => import('@root/modules/magic-terminal').then((module) => ({ default: module.MagicTerminalModule.router })));

const ContentWrapper = styled.div<{ isGhostLogin: boolean }>`
  ${({ isGhostLogin }) =>
    isGhostLogin
      ? `
    background: black;
    min-height: calc(100vh - 7px);
  `
      : ''}
`;

export const Root = () => {
  const { isGhostLogin } = useGhostLogin();
  useCheckPresetsSubscription();
  useCheckSharedBotSubscription();
  const user = useSelector(authSelector.user);
  const quizState = useSelector(authSelector.quizState);
  const isResetModalOpen = useSelector(authSelector.resetTwoFaModalOpen);

  useCheckAccounts();

  if (quizState && quizState.currentQuizVersion !== quizState.lastCompletedQuizVersion && quizState.quizRequired) {
    return <QuizModal />;
  }

  return (
    <GhostLoginWrapper>
      <ContentWrapper isGhostLogin={isGhostLogin}>
        <Outlet />
        <UpdateAccount />
        <ServiceAccountModal />
        <EmptyDDAccounts />
        {!!user && <CheckTwoFaEnabledModal />}
        {isResetModalOpen && <ResetTwoFaModal />}
      </ContentWrapper>
    </GhostLoginWrapper>
  );
};

const RootWithMaintenance = withMaintenanceMode(Root);

export const Router = () => {
  const { initialized } = useSelector(authSelector.main);

  useYupLocale();
  useRefreshSession();
  useCheckGhostLogin();
  useServiceAccountChecker();

  if (!initialized) {
    return <LoadingScreen />;
  }

  const router = createBrowserRouter([
    {
      path: '/',
      element: <RootWithMaintenance />,
      children: [
        {
          path: '/',
          element: (
            <ProtectedRoute>
              <DashboardRouter />
            </ProtectedRoute>
          ),
          errorElement: <ErrorPage />,
        },
        {
          path: 'assists/*',
          element: (
            <ProtectedRoute>
              <ExpertsRouter />
            </ProtectedRoute>
          ),
          errorElement: <ErrorPage />,
        },
        {
          path: 'share/:id',
          element: <ShareAssist />,
        },
        {
          path: 'ea-guide/*',
          element: (
            // <ProtectedRoute>
            <EAGuideRouter />
            // </ProtectedRoute>
          ),
          errorElement: <ErrorPage />,
        },
        {
          path: '/symbol-remap',
          element: (
            <ProtectedRoute>
              <SymbolRemapPage />
            </ProtectedRoute>
          ),
          errorElement: <ErrorPage />,
        },
        {
          path: 'accounts/*',
          element: (
            <ProtectedRoute>
              <AccountsRouter />
            </ProtectedRoute>
          ),
          errorElement: <ErrorPage />,
        },
        // {
        //   path: 'signals/*',
        //   element: (
        //     <ProtectedRoute>
        //       <SignalsRouter />
        //     </ProtectedRoute>
        //   ),
        //   errorElement: <ErrorPage />,
        // },
        {
          path: 'presets/*',
          element: (
            <ProtectedRoute>
              <PresetsRouter />
            </ProtectedRoute>
          ),
          errorElement: <ErrorPage />,
        },

        {
          path: 'settings',
          element: (
            <ProtectedRoute>
              <SettingsPage />
            </ProtectedRoute>
          ),
          errorElement: <ErrorPage />,
        },
        {
          path: 'subscriptions',
          element: (
            <ProtectedRoute>
              <SubscriptionsPage />
            </ProtectedRoute>
          ),
          errorElement: <ErrorPage />,
        },

        {
          path: 'subscriptions/:id',
          element: (
            <ProtectedRoute>
              <SubscriptionPaymentPage />
            </ProtectedRoute>
          ),
          errorElement: <ErrorPage />,
        },

        {
          path: 'marketplace/*',
          element: (
            <ProtectedRoute>
              <MarketPlaceRouter />
            </ProtectedRoute>
          ),
          errorElement: <ErrorPage />,
        },
        {
          path: 'legals/*',
          element: <LegalsRouter />,
          errorElement: <ErrorPage />,
        },
        {
          path: 'scanners/*',
          element: (
            <ProtectedRoute>
              <ExternalScannersRouter />
            </ProtectedRoute>
          ),
          errorElement: <ErrorPage />,
        },
        {
          path: 'magic-terminal/*',
          element: (
            <ProtectedRoute>
              <MagicTerminalRouter />
            </ProtectedRoute>
          ),
          errorElement: <ErrorPage />,
        },
        {
          path: '/*',
          element: <AuthRouter />,
          errorElement: <ErrorPage />,
        },
        {
          path: '*',
          element: <Navigate to='/' replace />,
          errorElement: <ErrorPage />,
        },
      ],
    },
  ]);

  return (
    <Suspense fallback={<LoadingScreen />}>
      <RouterProvider router={router} />
      <div className='h-[70px] md:h-0'></div>
    </Suspense>
  );
};
