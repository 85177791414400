import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-multi-carousel';
import { useDispatch, useSelector } from 'react-redux';

import { IAccount } from '@root/modules/accounts';
import { useConnectDisconnectAccount } from '@root/modules/accounts/hooks/use-connect-disconnect-account';
import { useGetAccounts } from '@root/modules/accounts/hooks/use-get-accounts';
import { updateAccountService } from '@root/modules/accounts/services/update-account.service';
import { accountsSelector } from '@root/modules/accounts/store/accounts.selector';
import { accountsSlice } from '@root/modules/accounts/store/accounts.slice';
import { authSelector } from '@root/shared-files/modules/auth/store';
import { SERVICE_ACCOUNT_MODAL_SHOWN } from '@root/shared-files/modules/shared/constants/local-storage-keys';
import { useLocalStorage } from '@root/shared/hooks/useLocalStorage';
import { notify } from '@root/shared/utils/notification';

export const useServiceAccountModal = () => {
  const dispatch = useDispatch();
  const [isShown, setShown] = useLocalStorage(SERVICE_ACCOUNT_MODAL_SHOWN, false);
  const serviceAccountModalOpened = useSelector(accountsSelector.serviceAccountModalOpened);
  const { t: commonT } = useTranslation('common');
  const carouselRef = React.useRef<Carousel | null>(null);
  const user = useSelector(authSelector.user);
  const { data: accounts, refetch } = useGetAccounts(!!user);
  const [accountsForUpdate, setAccountsForUpdate] = useState<IAccount[]>([]);
  const { disconnectAccountAsync, connectAccountAsync } = useConnectDisconnectAccount();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (accounts?.length && !accountsForUpdate.length) {
      const accountsWithoutServiceAccount = accounts.filter((account) => !account.isServiceAccountAssigned && account?.serviceAccounts?.length > 1);
      if (accountsWithoutServiceAccount.length) {
        setAccountsForUpdate(accountsWithoutServiceAccount);
      } else {
        const accountsWithWrongMapping = accounts.filter((account) => {
          const symbolsInMapping = Object.values(account.symbolsMapping as Record<string, string>);
          return symbolsInMapping.some((symbol) => symbol && !account.symbols.includes(symbol));
        });

        if (accountsWithWrongMapping.length) {
          setAccountsForUpdate(accountsWithWrongMapping);
        }
      }
    }
  }, [accounts, accountsForUpdate]);

  useEffect(() => {
    if (activeIndex) {
      carouselRef.current?.next(activeIndex);
    }
  }, [activeIndex]);

  const accountChanged = useMemo(() => {
    const account = accountsForUpdate[activeIndex];
    const initialServiceAccountId = accounts?.find((a) => a.id === account?.id)?.serviceAccountId;
    return account?.serviceAccountId !== initialServiceAccountId;
  }, [accountsForUpdate, activeIndex, accounts]);

  const closeModal = () => {
    dispatch(accountsSlice.actions.serviceAccountModalOpened(false));
  };

  const selectServiceAccountOption = useCallback(
    (option) => {
      setAccountsForUpdate((prev) => {
        const accounts = [...prev];
        accounts[activeIndex].serviceAccountId = option.value;
        return accounts;
      });
    },
    [setAccountsForUpdate, activeIndex],
  );

  const handleSave = useCallback(async () => {
    setIsLoading(true);
    const account = accountsForUpdate[activeIndex];

    if (account && account.isSignedIn) {
      await disconnectAccountAsync({
        accountId: account.id,
      });
    }

    const result = await updateAccountService({
      accountId: accountsForUpdate[activeIndex].id,
      serviceAccountId: account.serviceAccountId,
      proxyServerId: account.proxyServerId,
      maxDrawDownLimit: account.maxDrawDownLimit,
    });

    if (account && account.isSignedIn) {
      await connectAccountAsync({
        accountId: account.id,
      });
    }

    if (result.status === 200) {
      setShown(true);
      notify({ type: 'success', text: commonT('success') });
      if (accountsForUpdate[activeIndex + 1]) {
        setActiveIndex((prev) => prev + 1);
      } else {
        await refetch();
        closeModal();
      }
    } else {
      notify({ type: 'danger', text: result.payload }, { data: result.errorData });
    }

    setIsLoading(false);
  }, [accountsForUpdate, activeIndex, closeModal, commonT, connectAccountAsync, disconnectAccountAsync, refetch, setShown]);

  const state = { isLoading, carouselRef, accounts: accountsForUpdate, modalOpen: serviceAccountModalOpened, accountChanged, isShown };
  const handlers = { closeModal, selectServiceAccountOption, handleSave, setShown };

  return [state, handlers] as [typeof state, typeof handlers];
};
