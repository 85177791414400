import clsx from 'clsx';
import { useFormikContext } from 'formik';
import { FC, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { CreateExpertDto } from '@root/modules/experts/dtos/create-expert.dto';
import { SelectField, TextField } from '@root/shared/form';
import { InfoTooltipIcon } from '@root/shared/icons/info-tooltip-icon';
import { IconLabel } from '@root/shared/ui/form';
import { InfoTooltip } from '@root/shared/ui/info-tooltip';
import { Text, Title } from '@root/shared/ui/typography';

import { useCreateExpertFieldOptions } from '../hooks/use-create-expert-options';

export const ManualMoneyManagement: FC<{
  type: CreateExpertDto['manualMoneyManagement']['type'];
  isPartialEdit?: boolean;
  isMagicTerminal?: boolean;
}> = ({ type, isPartialEdit, isMagicTerminal }) => {
  const { t } = useTranslation('forex-experts');
  const options = useCreateExpertFieldOptions();
  const { values, setFieldValue } = useFormikContext<CreateExpertDto>();

  const onTypeChange = useCallback(
    (option) => {
      const value = option.value;
      const basedOn = values.manualMoneyManagement.basedOn;
      setFieldValue('manualMoneyManagement.type', value);
      setFieldValue('maxDrawDownLimit.basedOn', basedOn);
    },
    [setFieldValue, values.manualMoneyManagement.basedOn],
  );

  const onBasedOnChange = useCallback(
    (option) => {
      const value = option.value;
      setFieldValue('manualMoneyManagement.basedOn', value);
      setFieldValue('maxDrawDownLimit.basedOn', value);
    },
    [setFieldValue],
  );

  return (
    <div>
      {!isPartialEdit && (
        <Title level={7} className='mb-4'>
          {t('steps.moneyManagement.title')}
        </Title>
      )}

      <SelectField
        name='manualMoneyManagement.type'
        label={
          <IconLabel
            icon={
              <InfoTooltip content={<Text size='sm'>{t('fields.manualMoneyManagement.type.description')}</Text>}>
                <InfoTooltipIcon />
              </InfoTooltip>
            }
          >
            {t('fields.manualMoneyManagement.type.label')}
          </IconLabel>
        }
        wrapperClassName={clsx({ 'mb-4': !isPartialEdit, 'mb-2': isPartialEdit })}
        placeholder={t('fields.manualMoneyManagement.type.placeholder')}
        options={options.manualMoneyManagementType}
        onChange={onTypeChange}
      />
      {['0', '2'].includes(type) && (
        <TextField
          name='manualMoneyManagement.lotsize'
          label={
            <IconLabel
              icon={
                <InfoTooltip content={<Text size='sm'>{t('fields.manualMoneyManagement.lotsize.description')}</Text>}>
                  <InfoTooltipIcon />
                </InfoTooltip>
              }
            >
              {t('fields.manualMoneyManagement.lotsize.label')}
            </IconLabel>
          }
          wrapperClassName={clsx({ 'mb-4': !isPartialEdit, 'mb-2': isPartialEdit })}
          placeholder={t('fields.manualMoneyManagement.lotsize.placeholder')}
        />
      )}
      {['1'].includes(type) && (
        <TextField
          name='manualMoneyManagement.riskPercent'
          label={
            <IconLabel
              icon={
                <InfoTooltip content={<Text size='sm'>{t('fields.manualMoneyManagement.riskPercent.description')}</Text>}>
                  <InfoTooltipIcon />
                </InfoTooltip>
              }
            >
              {t('fields.manualMoneyManagement.riskPercent.label')}
            </IconLabel>
          }
          wrapperClassName={clsx({ 'mb-4': !isPartialEdit, 'mb-2': isPartialEdit })}
          placeholder={t('fields.manualMoneyManagement.riskPercent.placeholder')}
          suffix='%'
        />
      )}
      {['1', '2'].includes(type) && (
        <SelectField
          name='manualMoneyManagement.basedOn'
          label={
            <IconLabel
              icon={
                <InfoTooltip content={<Text size='sm'>{t('fields.manualMoneyManagement.basedOn.description')}</Text>}>
                  <InfoTooltipIcon />
                </InfoTooltip>
              }
            >
              {t('fields.manualMoneyManagement.basedOn.label')}
            </IconLabel>
          }
          wrapperClassName={clsx({ 'mb-4': !isPartialEdit, 'mb-2': isPartialEdit })}
          placeholder={t('fields.manualMoneyManagement.basedOn.placeholder')}
          options={options.manualMoneyManagementBasedOn}
          onChange={onBasedOnChange}
        />
      )}
      {['2'].includes(type) && (
        <TextField
          name='manualMoneyManagement.k'
          label={
            <IconLabel
              icon={
                <InfoTooltip content={<Text size='sm'>{t('fields.manualMoneyManagement.k.description')}</Text>}>
                  <InfoTooltipIcon />
                </InfoTooltip>
              }
            >
              {t('fields.manualMoneyManagement.k.label')}
            </IconLabel>
          }
          wrapperClassName={clsx({ 'mb-4': !isPartialEdit, 'mb-2': isPartialEdit })}
          placeholder={t('fields.manualMoneyManagement.k.placeholder')}
        />
      )}

      {!isMagicTerminal && (
        <TextField
          name='maxManualActiveTrades'
          label={
            <IconLabel
              icon={
                <InfoTooltip
                  keepOnMouseLeave
                  content={
                    <Text size='sm'>
                      <Trans i18nKey={'forex-templates:form.fields.manualMoneyManagement.maxActiveTrades.description'} />
                    </Text>
                  }
                >
                  <InfoTooltipIcon />
                </InfoTooltip>
              }
            >
              {t('fields.manualMoneyManagement.maxActiveTrades.label')}
            </IconLabel>
          }
          wrapperClassName={clsx({ 'mb-4': !isPartialEdit, 'mb-2': isPartialEdit })}
          placeholder={t('fields.manualMoneyManagement.maxActiveTrades.placeholder')}
        />
      )}
    </div>
  );
};
