import isEmpty from 'lodash/isEmpty';

import { AxiosError } from 'axios';

import { IHttpResponse } from '@root/shared/utils/http-service';

const getErrorsMessages = (obj: Record<string, any>): any[] => {
  const values: any[] = [];

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        values.push(...getErrorsMessages(obj[key]));
      } else {
        values.push(obj[key]);
      }
    }
  }

  return values;
};

export const handleAxiosError = (error: AxiosError): IHttpResponse<400, string> => {
  console.error(error);

  if (error.isAxiosError && !isEmpty(error.response?.data.errors)) {
    const errors = error.response?.data.errors;
    const messages = getErrorsMessages(errors).join(', ');

    return {
      status: 400,
      payload: messages,
      errorData: error.response?.data?.errorData,
    };
  }

  if (error.isAxiosError && error.response?.data.message) {
    const message: string | string[] = error.response?.data.message;
    const parsedMessage: string = Array.isArray(message) ? message.join(',') : message;

    return {
      status: 400,
      payload: parsedMessage,
      errorData: error.response?.data?.errorData,
    };
  }

  return {
    status: 400,
    payload: error.message,
    errorData: error.response?.data?.errorData,
  };
};
