import { FxSessionManagementActionType } from '@3lgn/shared/dist/constants/fx';
import '@3lgn/shared/dist/constants/roles';
import { Subscription as SubscriptionRoles } from '@3lgn/shared/dist/constants/roles';

import { useFormikContext } from 'formik';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { authSelector } from '@root/shared-files/modules/auth/store';
import { SelectField, TextField } from '@root/shared/form';
import { CheckboxField } from '@root/shared/form';
import { SwitchField } from '@root/shared/form/fields/switch-field';
import { InfoIcon } from '@root/shared/icons/info-icon';
import { Text, Title } from '@root/shared/ui/typography';

import { CreateExpertDto } from '../dtos/create-expert.dto';
import { useCreateExpertFieldOptions } from '../hooks/use-create-expert-options';
import { Tooltip } from './drawdown.ui';

type Props = {
  isPartialEdit?: boolean;
};

export const SessionManagement: FC<Props> = () => {
  const { t } = useTranslation('forex-experts', { keyPrefix: 'fields.sessionManagement' });
  const { t: tCommon } = useTranslation('common');
  const { values, setFieldValue } = useFormikContext<CreateExpertDto>();
  const subscriptionInfo = useSelector(authSelector.getSubscriptionInfo);

  const { sessionManagementTypeOptions, sessionManagementSessionOptions } = useCreateExpertFieldOptions(values.unitType);

  const onUseChange = useCallback(
    (event) => {
      const checked = !!event.target.checked;
      setFieldValue('sessionManagement.use', checked);
    },
    [setFieldValue],
  );

  const onSessionsChange = useCallback(
    (options, item) => {
      const { option: selectedOption, removedValue } = item;

      if (selectedOption?.value !== 'all') {
        const newValue = options.filter((option) => option.value !== 'all').map((item) => item.value);
        setFieldValue('sessionManagement.sessions', newValue);
      } else if (removedValue) {
        const newValue = options.filter((option) => option.value !== 'all' && option.value !== removedValue.value).map((item) => item.value);

        setFieldValue('sessionManagement.sessions', newValue);
      } else {
        setFieldValue('sessionManagement.sessions', ['all']);
      }
    },
    [setFieldValue],
  );

  const allowSessionManagement = subscriptionInfo?.permissions['SUBSCRIPTION_FX_ORDER_SESSION_MANAGEMENT'].value;
  const isExpertForm = !!(values as CreateExpertDto)?.unitType;

  return (
    <div>
      <SwitchField
        name='sessionManagement.use'
        label={
          <Tooltip content={<Text size='sm'>{tCommon('comingSoonWithProSub')}</Text>}>
            <div className='flex justify-start items-center gap-x-2 cursor-pointer'>
              <Title level={7}>{t('use.label')}</Title>
              <InfoIcon className='flex-shrink-0' width={16} height={16} />
            </div>
          </Tooltip>
        }
        wrapperClassName='mb-2'
        onChange={onUseChange}
        disabled={!isExpertForm || !allowSessionManagement}
      />
      {values.sessionManagement?.use && (
        <>
          <div className='mb-4'>
            <SelectField
              options={sessionManagementSessionOptions}
              name='sessionManagement.sessions'
              label={
                <Tooltip content={<Text size='sm'>{t('sessions.hint')}</Text>}>
                  <div className='flex justify-start items-center gap-x-2 cursor-pointer'>
                    <Text size='sm'>{t('sessions.label')}</Text>
                    <InfoIcon className='flex-shrink-0' width={16} height={16} />
                  </div>
                </Tooltip>
              }
              placeholder={t('sessions.placeholder')}
              checkedDescriptionOption
              isMulti
              closeMenuOnSelect={false}
              blurInputOnSelect={false}
              onChange={onSessionsChange}
            />
          </div>
          <div className='mb-4'>
            <Text size='base' className='mb-2 font-semibold'>
              {t('type.label')}
            </Text>
            {sessionManagementTypeOptions.map((option, index) => (
              <CheckboxField
                name='sessionManagement.type'
                className='mb-4'
                key={index}
                label={
                  option.description ? (
                    <Tooltip content={<Text size='sm'>{option.description}</Text>}>
                      <div className='flex justify-start items-center gap-x-2 cursor-pointer'>
                        <Text size='sm'>{option.label}</Text>
                        <InfoIcon className='flex-shrink-0' width={16} height={16} />
                      </div>
                    </Tooltip>
                  ) : (
                    option.label
                  )
                }
                value={option.value}
                onChange={() => setFieldValue('sessionManagement.type', option.value)}
                checked={values.sessionManagement?.type === option.value}
                type='radio'
                hideError
              />
            ))}
          </div>
          {[FxSessionManagementActionType.CLOSE_IF_IN_PROFIT as string].includes(values.sessionManagement.type) && (
            <div className='mb-4'>
              <TextField name='sessionManagement.threshold' label={t('threshold.label')} type='string' placeholder={t('threshold.placeholder')} suffix={values.unitType} />
            </div>
          )}
        </>
      )}
    </div>
  );
};
