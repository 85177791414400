import clsx from 'clsx';
import React, { FC, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useGetAccounts } from '@root/modules/accounts/hooks/use-get-accounts';
import { DescriptionPopover } from '@root/modules/experts/components/description-popover';
import { ExpertCardHeader } from '@root/modules/experts/components/expert-card-header';
import { SymbolsPopover } from '@root/modules/experts/components/symbols-popover';
import { useProviderTraders } from '@root/modules/marketplace/hooks/use-provider-traders';
import { useGetOrdersCount } from '@root/modules/orders/hooks/use-get-orders-count';
import { ImageWithFallback } from '@root/shared/ui/image/image-with-fallback';
import { AmountWithCurrency } from '@root/shared/ui/money';
import { Text, Title } from '@root/shared/ui/typography';
import { getGainLostColor } from '@root/shared/utils/colors';
import { globalRound } from '@root/shared/utils/number/round';

import { IExpert } from '../types/expert';

export type ExpertCardProps = {
  data: IExpert;
  pnl: number;
};

export const ExpertCard: FC<ExpertCardProps> = ({ data, pnl }) => {
  const ref = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('forex-experts');

  const { traders } = useProviderTraders();

  const { data: accounts } = useGetAccounts();

  const brokerData = useMemo(() => accounts?.find((item) => item.id === data?.accountId), [accounts, data?.accountId]);
  const brokerServerName = brokerData?.server || '';

  const selectedTrader = useMemo(() => traders.find((trader) => trader.providerId === data.providerId), [traders, data.providerId]);

  const displaySymbols = data.symbols.slice(0, 3);
  const hiddenSymbols = data.symbols.slice(3);
  const showPopover = (ref?.current?.clientWidth || 0) > (containerRef?.current?.clientWidth || 0) - 40;

  const { historyOrdersCountByExpertId, activeOrdersCountByExpertId } = useGetOrdersCount(data.id, data.accountId);

  return (
    <div className='bg-gray-800 rounded relative flex flex-col justify-between pb-5'>
      <p ref={ref} className='fixed -top-20 text-gray-500 mb-2 h-6 text-sm overflow-hidden text-ellipsis whitespace-nowrap'>
        {data.description}
      </p>
      <ExpertCardHeader data={data} />
      <div ref={containerRef} className='px-5'>
        <Link to={`/assists/${data?.id}`}>
          <div className='flex items-center gap-x-1'>
            {(data.allowClone || data.allowShare) && <div className='w-2 h-2 rounded-lg bg-success-400 flex-shrink-0' />}
            <Title level={6} bold className='text-primary-400'>
              {data.name}
            </Title>
          </div>
        </Link>
        <p className='text-gray-500 mb-2 h-6 text-sm overflow-hidden text-ellipsis whitespace-nowrap'>
          {data.account?.user} {brokerServerName}
        </p>
        <DescriptionPopover description={data.description || ''} showPopover={showPopover}>
          <p className='text-gray-500 mb-2 h-6 text-sm overflow-hidden text-ellipsis whitespace-nowrap'>{data.description}</p>
        </DescriptionPopover>
      </div>
      <div className='px-5 pt-2'>
        {selectedTrader ? (
          <div className='flex items-center gap-x-2 mb-4'>
            <div className='flex'>
              <ImageWithFallback width={20} height={20} src={selectedTrader.logo} label={selectedTrader?.name?.substring(2)} />
              <Text size='sm' className='ml-2 font-semibold'>
                {selectedTrader?.name || '-'}
              </Text>
            </div>
          </div>
        ) : data.useManualTrades ? (
          <div className='flex items-center gap-x-2 mb-4'>
            <Text size='sm' className='ml-2 font-semibold'>
              {t(`fields.strategyTypeWebhook.options.${1}`)}
            </Text>
          </div>
        ) : (
          <></>
        )}
        <div className='px-4 py-3 rounded bg-grayscale-700 flex justify-between items-start gap-x-2'>
          <div>
            <Text size='sm' className='text-grayscale-400 mb-1'>
              {t('item.stats.pnl')}
            </Text>
            <Text size='sm' className={`font-semibold ${getGainLostColor(pnl)}`}>
              <AmountWithCurrency currency={brokerData?.currency} value={globalRound(pnl, 2)} />
            </Text>
          </div>
          <div>
            <Text size='sm' className='text-grayscale-400 mb-1'>
              {t('item.stats.activeTrades')}
            </Text>
            <Text size='sm' className='font-semibold'>
              {activeOrdersCountByExpertId}
            </Text>
          </div>
          <div>
            <Text size='sm' className='text-grayscale-400 mb-1'>
              {t('item.stats.closedTrades')}
            </Text>
            <Text size='sm' className='font-semibold'>
              {historyOrdersCountByExpertId}
            </Text>
          </div>
        </div>
        <div className='border-b border-gray-700 my-3' />
        {!!displaySymbols.length && (
          <div className='flex flex-wrap -m-1'>
            {displaySymbols.map((symbol) => (
              <div key={symbol} className='m-1 text-[15px] bg-gray-700 px-2 py-px rounded-full'>
                {symbol}
              </div>
            ))}
            {!!hiddenSymbols.length && (
              <SymbolsPopover symbols={hiddenSymbols}>
                <button className='m-1 text-[15px] bg-gray-700 px-2 py-px rounded-full hover:bg-gray-900 transition'>+{hiddenSymbols.length}</button>
              </SymbolsPopover>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
