import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { queryClient } from '@root/infra/query';
import { useGetAccountCurrency } from '@root/modules/accounts/hooks/use-get-account-currency';
import { ExpertStatusBadge } from '@root/modules/experts/components/expert-status';
import { useGetExpertStatus } from '@root/modules/experts/hooks/use-get-expert-status';
import { createExpertService } from '@root/modules/experts/services/create-expert.service';
import { IExpert } from '@root/modules/experts/types/expert';
import { TabType } from '@root/modules/orders/enums/orders';
import { useGetOrders } from '@root/modules/orders/hooks/use-get-orders';
import { useGetOrdersCount } from '@root/modules/orders/hooks/use-get-orders-count';
import { useGetOrdersProfit } from '@root/modules/orders/hooks/use-get-orders-profit';
import { CheckIcon } from '@root/shared/icons/check-icon';
import { CopyIcon } from '@root/shared/icons/copy-icon';
import { CrossIcon } from '@root/shared/icons/cross-icon';
import { LoadingIcon } from '@root/shared/icons/loading-icon';
import { PencilIcon } from '@root/shared/icons/pencil-icon';
import { ColoredValue } from '@root/shared/ui/colored-value';
import { Input } from '@root/shared/ui/form';
import { Text } from '@root/shared/ui/typography';
import { copyToClipboard } from '@root/shared/utils/helpers/copy-to-clipboard';
import { notify } from '@root/shared/utils/notification';

type Props = {
  expert: IExpert;
};

export const MainInfo: FC<Props> = ({ expert }) => {
  const [editingName, setEditingName] = useState<boolean>(false);
  const [name, setName] = useState<string>(expert?.name || '');
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation('forex-experts');
  const { currencySymbol, currency } = useGetAccountCurrency(expert.accountId);

  const [{ totalOpenOrders }] = useGetOrders({
    enabled: true,
    expertId: expert.id,
    tab: TabType.PENDING,
    selectedBroker: expert.accountId,
  });

  const { historyOrdersCountByExpertId } = useGetOrdersCount(expert.id);
  const { status } = useGetExpertStatus(expert);

  const { todayPnl, totalOrdersData, openOrdersData } = useGetOrdersProfit({
    selectedBroker: expert.accountId,
    expertId: expert.id,
  });

  const saveSettings = useCallback(async () => {
    setLoading(true);
    const result = await createExpertService({ name, id: expert?.id || '' } as any, true);

    if (result.status === 200) {
      queryClient.invalidateQueries(['experts', expert.id]);
      queryClient.invalidateQueries(['experts']);
    } else {
      notify({ type: 'danger', text: result.payload }, { data: result.errorData });
    }
    setEditingName(false);
    setLoading(false);
  }, [expert.id, name]);

  return (
    <div className='w-full p-5 bg-gray-800 rounded'>
      <div className='w-full border-b border-gray-700 mb-2 pb-2'>
        <div className='w-full flex justify-between items-center'>
          <div className='flex justify-start items-center gap-x-2'>
            {editingName ? (
              <>
                <Input
                  type='text'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  variant={name?.length && name?.length <= 70 ? undefined : 'danger'}
                  size='small'
                  className='!py-0 !px-3 !text-[15px]'
                />
                <div className='flex justify-end gap-x-1'>
                  <button
                    type='button'
                    className='w-6 h-6 flex justify-center items-center p-0 rounded bg-gray-100 bg-opacity-30'
                    onClick={() => {
                      setEditingName(false);
                      setName(expert.name);
                    }}
                  >
                    <CrossIcon width={12} height={12} />
                  </button>
                  {loading ? (
                    <div className='h-6 w-6 flex justify-center items-center'>
                      <LoadingIcon />
                    </div>
                  ) : (
                    <button type='button' className='w-6 h-6 flex justify-center items-center p-0 rounded bg-gray-100 bg-opacity-30' onClick={saveSettings}>
                      <CheckIcon width={16} height={16} />
                    </button>
                  )}
                </div>
              </>
            ) : (
              <>
                <Text className='font-semibold'>{expert.name}</Text>
                <button>
                  <PencilIcon width={16} height={16} className='cursor-pointer hover:text-primary-400' onClick={() => setEditingName(true)} />
                </button>
              </>
            )}
            <CopyIcon className='cursor-pointer hover:text-primary-400' onClick={() => copyToClipboard(expert.id)} />
            <ExpertStatusBadge status={status} />
          </div>
        </div>
        <div className='flex justify-between'>
          <p className='text-gray-500 text-sm'>{expert?.description}</p>
        </div>
      </div>

      <div className='w-full grid grid-cols-2 gap-x-12 gap-y-1'>
        <div className='flex flex-col gap-y-1.5'>
          <div className='w-full flex justify-between items-center gap-x-2'>
            <Text size='sm' className='text-grayscale-400 font-medium'>
              {t('mainInfo.todayPnl')}
            </Text>
            <Text className='flex gap-x-2' size='sm' bold>
              <ColoredValue value={todayPnl.profit} currencyPrefix={currencySymbol} />
            </Text>
          </div>

          <div className='w-full flex justify-between items-center gap-x-2'>
            <Text size='sm' className='text-grayscale-400 font-medium'>
              {t('mainInfo.totalPnl')}
            </Text>
            <Text className='flex gap-x-2' size='sm' bold>
              <ColoredValue value={totalOrdersData[currency]?.profit} currencyPrefix={currencySymbol} />
            </Text>
          </div>

          <div className='w-full flex justify-between items-center gap-x-2'>
            <Text size='sm' className='text-grayscale-400 font-medium'>
              {t('mainInfo.unrealizedPnl')}
            </Text>
            <Text className='flex gap-x-2' size='sm' bold>
              <ColoredValue value={openOrdersData?.[currency]?.profit || 0} currencyPrefix={currencySymbol} />
            </Text>
          </div>
        </div>

        <div className='flex flex-col gap-y-1.5'>
          <div className='w-full flex justify-between items-center gap-x-2'>
            <Text size='sm' className='text-grayscale-400 font-medium'>
              {t('mainInfo.activeTrades')}
            </Text>
            <Text size='sm'>{totalOpenOrders}</Text>
          </div>

          <div className='w-full flex justify-between items-center gap-x-2'>
            <Text size='sm' className='text-grayscale-400 font-medium'>
              {t('mainInfo.closedTrades')}
            </Text>
            <Text size='sm'>{historyOrdersCountByExpertId}</Text>
          </div>
        </div>
      </div>
    </div>
  );
};
